import { TAdvCommonProperties } from "@components/other/common-properties";
import { recoilPageState } from "@data/dynamic-page";
import { LAN } from "@data/language/strings";
import { buildPageIDForVariableID } from "@data/parameters";
import { TAdvDesignerComponentProps } from "@feature/Designer/types/component-props";
import { getDesignerModeComponentStyle, getSelectedComponentStyle } from "@feature/Designer/utils";
import {
    IDropdownOption,
    IDropdownProps,
    IDropdownStyleProps,
    IDropdownStyles,
    IRenderFunction,
    IStyleFunctionOrObject,
    SelectableOptionMenuItemType,
} from "@fluentui/react";
import { usePrevious } from "@fluentui/react-hooks";
import {
    EAdvValueBinderType,
    IsValueBindingTrivial,
    TAdvValueBindingParams,
    useAdvValueBinder,
    useAdvValueBinderAsArrayNoDataType,
    useAdvValueBinderNoDataType,
} from "@hooks/dynamic/useAdvValueBinder";
import { TAdvTranslationText, useT } from "@hooks/language/useTranslation";
import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import { useAdvEffect } from "@hooks/react-overload/useAdvEffect";
import useAdvRecoilValue from "@hooks/recoil-overload/useAdvRecoilValue";
import useAdvComponent from "@hooks/useAdvComponent";
import { useAdvMemoWithUpdater } from "@hooks/useAdvMemoWithUpdater";
import useAdvTheme from "@hooks/useAdvTheme";
import { EAdvValueDataTypes } from "@utils/data-types";
import { deepCompareJSXProps } from "@utils/deep-compare";
import { combineStyles } from "@utils/styling";
import { nanoid } from "nanoid";
import React, { useMemo, useRef, useState } from "react";

import AdvStack from "@components/layout/stack/stack";
import AdvIcon from "@components/other/icon/icon";
import { EPageComponentSizeType, TPageComponentProps } from "@components/page-component";
import {
    Button,
    Combobox,
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerHeaderTitle,
    Dropdown,
    Field,
    FluentProvider,
    Label,
    Option,
    makeStyles,
} from "@fluentui/react-components";
import { Virtualizer, useStaticVirtualizerMeasure } from "@fluentui/react-components/unstable";
import { createV9Theme } from "@fluentui/react-migration-v8-v9";
import { IsWebActionTrivial, useAdvWebAction } from "@hooks/dynamic/useAdvWebAction";
import { TAdvWebActionParams } from "@hooks/dynamic/useAdvWebAction.types";
import useIsFirstRender from "@hooks/misc/useIsFirstRender";
import { CloseIcon } from "@themes/icons";
import { TCommonValueProps } from "..";
import AdvButton from "../button/button";
import AdvCheckbox from "../checkbox/checkbox";
import AdvSearchInput from "../text-input-new/search-input/search-input";

export type TAdvDropdownItem<T = any> = IDropdownOption<T> & {
    ignoreTranslation?: boolean;

    translatedLanguageID?: number;
    originalText?: string;
    translationError?: boolean;
};
export type TAdvSelectableOption<T = any> = Omit<TAdvDropdownItem<T>, "IsSelected">;
export type TAdvDropdownStyles = IDropdownStyles; /* do not change */
export type TAdvDropdownStyleProps = IDropdownStyleProps; /* do not change */

export const AdvDropdownText = ({ children }: { children: string }) => {
    return <div className="adv-SingleLine">{children}</div>;
};
const AdvDropdownValue = ({ children }: { children: string }) => {
    return <div className="adv-SingleLine">{children}</div>;
};

const useStylesDropdown = makeStyles({
    checkIcon: {
        flexShrink: "0", //korrigiert einen fluentUI-bug (github.com/microsoft/fluentui/issues/32227)
    },
});

const AdvDropdownOption = ({
    value,
    children,
    ignoreTranslation,
    disabled,
}: {
    value: string | number;
    children: string | React.JSX.Element;
    ignoreTranslation?: boolean;
    disabled?: boolean;
}) => {
    const { t } = useT(typeof children == "string" ? children : "", ignoreTranslation);

    const styles = useStylesDropdown();

    return (
        <Option
            value={value?.toString()}
            text={t ?? (typeof children == "string" ? children : "")}
            disabled={disabled}
            checkIcon={{ className: styles.checkIcon }}
        >
            {children}
        </Option>
    );
};

export type TAdvDropdownProps = Omit<
    IDropdownProps,
    | "options"
    | "styles"
    | "label"
    | "key"
    | "selectedKey"
    | "selectedKeys"
    | "onChange"
    | "onValueChange"
    | "onRenderOption"
    | "onRenderTitle"
    | "description"
    | "placeholder"
> &
    TAdvDesignerComponentProps &
    TAdvCommonProperties &
    Omit<TCommonValueProps<TAdvDropdownItem>, "value" | "disabled"> & {
        translatableTextLabel: TAdvTranslationText;
        translatableTextLabelBindingParams?: TAdvValueBindingParams;
        translatableTextPlaceholder?: TAdvTranslationText;
        translatableTextPlaceholderBindingParams?: TAdvValueBindingParams;
        translatableTextDescription?: TAdvTranslationText;
        translatableTextDescriptionBindingParams?: TAdvValueBindingParams;
        options: TAdvDropdownItem[];
        styles?: IStyleFunctionOrObject<TAdvDropdownStyleProps, TAdvDropdownStyles>;
        /** @important Wenn ein String genutzt wird, muss dieser einem Key der {@link TAdvDropdownProps.options} entsprechen! */
        value?: string | TAdvDropdownItem | undefined;
        valueBindingParams?: TAdvValueBindingParams;

        // more complex option building, text and data splitted
        optionsText?: string[];
        optionsTextBindingParams?: TAdvValueBindingParams;
        optionsData?: any[];
        optionsDataBindingParams?: TAdvValueBindingParams;

        multiSelectBindingParams?: TAdvValueBindingParams;
        requiredBindingParams?: TAdvValueBindingParams;

        onRenderOption?: (item: any) => React.JSX.Element;
        onRenderTitle?: (item: any) => React.JSX.Element;

        hideWhenEmpty?: boolean;
        disabledWhenEmpty?: boolean;
        hideWhenBelowRecords?: number;

        disabled?: boolean;
        disabledBindingParams?: TAdvValueBindingParams;
        /**
         * Zeigt ein Infoicon, welches detalierte Informationen gibt, sobald der User mit dem Cursor drüber geht
         */
        info?: TAdvTranslationText;

        pageProps?: TPageComponentProps;

        onChangedEventActionParams?: TAdvWebActionParams;
        keyRef?: string;

        // TODO: this prop should be `isDropdown` & inside the combobox
        // just for testing it's the other way around, since dropdown is more used rn
        isCombobox?: boolean;
    };

const AdvDropdownItem = (
    { ignoreTranslation, text, ...props }: TAdvSelectableOption,
    defaultRender: IRenderFunction<TAdvSelectableOption>,
) => {
    const { t: translatedText, hasErr: hasTextErr } = useT(text, ignoreTranslation);
    const theme = useAdvTheme();
    return (
        <div style={hasTextErr ? { ...theme.custom.textNotTranslated } : undefined}>
            {defaultRender({
                ...props,
                text: translatedText ?? text,
            })}
        </div>
    );
};

const AdvDropdownImplComp = ({
    translatableTextLabel,
    translatableTextDescription,
    translatableTextPlaceholder,
    advhide,
    onRenderTitle,
    onRenderOption,
    info,
    styles: propStyles,
    value,
    designerData,
    designerProps,
    multiSelect = false,
    options,
    errorMessage,
    hideWhenEmpty = false,
    hideWhenBelowRecords = 0,
    disabledWhenEmpty = false,
    pageProps,
    isCombobox = false,
    disabled,
    onChangedEventActionParams,
    keyRef,
    dataArrayIndex = 0,
    ...props
}: TAdvDropdownProps & Pick<IDropdownProps, "onChange">) => {
    useAdvComponent(AdvDropdownImplComp, props);

    const valueAsStrOrInt = useMemo(
        () =>
            typeof value === "string" ||
            typeof value === "bigint" ||
            typeof value === "number" ||
            typeof value === "boolean"
                ? value
                : value != undefined
                ? value.key
                : "",
        [value],
    );
    const selectedKeys = useMemo<string[] | number[] | undefined>(() => {
        if (multiSelect === true) {
            return options
                .map((o) => (o.selected === true ? o.key : typeof o.key == "string" ? "" : -1))
                .filter((i) => i != "" && i != -1) as string[] | number[];
        }
        return undefined;
    }, [multiSelect, options]);

    const router = useAdvRouter();
    const variableID = useMemo(() => buildPageIDForVariableID(router.pageInfo), [router.pageInfo]);
    const pageState = useAdvRecoilValue(recoilPageState(variableID));
    const [wasInteractedWith, setWasInteractedWith] = useState<boolean>(false);
    const myErrorMessage = useMemo(() => {
        if (errorMessage !== undefined) return errorMessage;
        if (disabled !== undefined && disabled) return undefined;
        if (props.required === undefined || props.required == false) return undefined;
        if (wasInteractedWith == false && pageState.ShowRequiredErrors == false) return undefined;
        if (
            (multiSelect == false &&
                (valueAsStrOrInt == "" ||
                    options.find((o) => o.key == valueAsStrOrInt) === undefined)) ||
            (multiSelect && (selectedKeys === undefined || selectedKeys.length == 0))
        )
            return LAN.FORM_REQUIRED_ERROR.text;
    }, [
        errorMessage,
        multiSelect,
        options,
        pageState.ShowRequiredErrors,
        disabled,
        props.required,
        selectedKeys,
        valueAsStrOrInt,
        wasInteractedWith,
    ]);

    const theme = useAdvTheme();
    const { t: translatedLabel, hasErr: hasLabelError } = useT(translatableTextLabel);
    const { t: translatedPlaceholder, hasErr: hasPlaceholderError } = useT(
        translatableTextPlaceholder ?? (props.required === true ? LAN.PLEASE_SELECT.text : ""),
    );
    const { t: translatedErrorMessage, hasErr: hasErrorMessageError } = useT(myErrorMessage);
    const { t: translatedDescription, hasErr: hasDescriptionError } = useT(
        translatableTextDescription,
    );

    const styles = useMemo(() => {
        let styles = propStyles;
        if (hasLabelError)
            styles = combineStyles(styles, {
                label: { ...theme.custom.textNotTranslated },
            });
        if (hasErrorMessageError)
            styles = combineStyles(styles, {
                errorMessage: { ...theme.custom.textNotTranslated },
            });
        if (hasPlaceholderError && valueAsStrOrInt == "")
            styles = combineStyles(styles, {
                title: { ...theme.custom.textNotTranslated },
            });
        if (designerData?.renderAsDesigner ?? false) {
            styles = combineStyles(styles, { root: getDesignerModeComponentStyle(theme) });
            if (designerData?.isSelected ?? false)
                styles = combineStyles(styles, { root: getSelectedComponentStyle(theme, true) });
        }
        //  else if (props.disabled !== undefined && props.disabled) {
        //     styles = combineStyles(styles, { title: { background: "transparent !important" } });
        // }
        if (disabled !== undefined && disabled) {
            styles = combineStyles(styles, {
                title: { background: theme.palette.white + " !important" },
                label: { color: theme.palette.neutralPrimaryAlt + " !important" },
            });
        }
        return styles;
    }, [
        designerData?.isSelected,
        designerData?.renderAsDesigner,
        hasErrorMessageError,
        hasLabelError,
        hasPlaceholderError,
        propStyles,
        disabled,
        theme,
        valueAsStrOrInt,
    ]);

    const themeV9 = useMemo(() => createV9Theme(theme), [theme]);

    const isMobile =
        (pageProps?.sizeType ?? EPageComponentSizeType.DesktopWide) <=
        EPageComponentSizeType.Mobile;

    const [isOpen, setIsOpen] = useState(false);
    const [searchField, setSearchField] = useState("");

    const { options: optionsFiltered, key: optionsFilteredKey } = useMemo(() => {
        return {
            options: options.filter((o) => {
                if (o.hidden ?? false) return false;
                if (searchField == "" || o.text.toLowerCase().includes(searchField.toLowerCase())) {
                    return true;
                } else {
                    return false;
                }
            }),
            key: nanoid(),
        };
    }, [options, searchField]);

    const selectedOptions = useMemo(() => {
        return (
            ((multiSelect == true
                ? selectedKeys?.map((k) => k.toString())
                : [valueAsStrOrInt.toString()]) as string[] | undefined) ?? []
        );
    }, [multiSelect, selectedKeys, valueAsStrOrInt]);

    const curVal = useMemo(() => {
        return options
            .filter((o) => selectedOptions.includes(o.key?.toString() ?? ""))
            .map((o) => o.text)
            .join(", ");
    }, [options, selectedOptions]);

    const dropdownRef: React.Ref<HTMLButtonElement> = useRef(null);
    const searchFieldRef: React.Ref<HTMLInputElement> = useRef(null);
    const listCompRef = useRef<HTMLDivElement | null>(null);

    // Das ist ein Workaround für einen Bug in der Combobox von Fluent. Es konnte
    // passieren, dass der List-Container der Combobox teilweise außerhalb vom Viewport ist,
    // wenn viele Items und/oder ein kleinerer Viewport verwendet wird.
    // Ist nicht Ideal, evtl. in Zukunft prüfen ob noch nötig oder ob es anderweitig
    // behoben werden kann
    const { ref: refInner, height: innerHeight } = useResizeDetector<HTMLDivElement>({
        handleHeight: true,
    });
    useAdvEffect(() => {
        if (
            listCompRef.current != null &&
            dropdownRef.current != null &&
            isOpen &&
            innerHeight != undefined
        ) {
            const yPosDropdown = dropdownRef.current.getBoundingClientRect().y;
            // Prüfen, ob sich die Dropdown unter oder oberhalb von der mitte des Views befindet.
            // Wenn wir oberhalb sind, dann wird wahrscheinlich die Dropdown-List oberhalb des
            // Dropdown-Buttons angezeigt. In diesem Fall die höhe auf den Bereich darüber eingrenzen
            // und ansonsten darunter.
            // Wir ziehen hier immer 100px ab um die Topbar auszugleichen
            if ((window.innerHeight - 100) / 2 < yPosDropdown) {
                console.log("transform", listCompRef.current.style.transform);
                listCompRef.current.style.maxHeight =
                    "calc(" + yPosDropdown.toString() + "px - 100px)";
            } else {
                listCompRef.current.style.maxHeight =
                    "calc(100vh - 100px - " + yPosDropdown.toString() + "px)"; // 20px als puffer abziehen, sieht besser aus
            }
        }
    }, [listCompRef, dropdownRef, /* effect dep */ isOpen, /* effect dep */ innerHeight]);

    const itemHeight = 32; //This should match the height of each item in the listbox
    const { virtualizerLength, bufferItems, bufferSize, scrollRef } = useStaticVirtualizerMeasure({
        defaultItemSize: itemHeight,
        direction: "vertical",
    });

    /**
     *Schließt die Dropdownauswahl und setzt den Fokus auf die Dropdown
     */
    const closeDropdown = useAdvCallback(() => {
        setIsOpen(false);

        // Mit Delay den Fokus umsetzen. Wenn man es direkt
        // macht, dann klappt es nicht...
        setTimeout(() => {
            dropdownRef.current?.focus();
        }, 1);
    }, [dropdownRef]);

    const [, , onChangedActionFunc, shouldUseBoundValue] = useAdvWebAction(
        keyRef ?? "",
        dataArrayIndex,
        onChangedEventActionParams,
    );

    const isFirstRender = useIsFirstRender();
    const previousValue = usePrevious(curVal);
    useAdvEffect(() => {
        if (shouldUseBoundValue == false) return;
        if (previousValue === curVal) return;
        if (isFirstRender) return;
        onChangedActionFunc();
    }, [isFirstRender, onChangedActionFunc, previousValue, shouldUseBoundValue, curVal]);

    const shouldForceDisable =
        options.length == 0 && disabledWhenEmpty && designerProps === undefined;

    if (advhide === true && designerProps === undefined) return <></>;
    if (options.length == 0 && hideWhenEmpty && designerProps === undefined) return <></>;
    if (options.length < hideWhenBelowRecords && designerProps === undefined) return <></>;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const VariantType = isCombobox ? Combobox : Dropdown;

    return (
        <FluentProvider
            theme={themeV9}
            style={{ backgroundColor: "transparent" }}
            className="foreground"
        >
            <Label
                disabled={disabled === true || shouldForceDisable}
                style={{
                    color: designerProps === undefined ? undefined : theme.palette.themePrimary,
                }}
                required={props.required}
            >
                {translatedLabel}
            </Label>
            <Field
                hint={translatedDescription}
                // TODO Validation/ErrorMessage für Dropdowns implementieren
                // validationState={
                //     translatedErrorMessage != undefined && translatedErrorMessage != ""
                //         ? "error"
                //         : "none"
                // }
                // validationMessage={translatedErrorMessage}
                style={{ maxWidth: "100%" }}
            >
                <VariantType
                    ref={dropdownRef as any}
                    multiselect={multiSelect}
                    onClick={() => {
                        setIsOpen(!isOpen);
                    }}
                    open={isMobile ? false : isOpen}
                    onKeyUp={(e) => {
                        // Key-Events wie bei Enter oder Tab rausfiltern
                        if (e.key.length <= 1) {
                            setSearchField(e.key);
                            searchFieldRef.current?.focus();
                        }
                    }}
                    onOpenChange={(e, d) => {
                        setIsOpen(d.open);
                    }}
                    onOptionSelect={(ev, data) => {
                        if (props.onChange != undefined) {
                            const foundOption = optionsFiltered.find(
                                (o) => data.optionValue == o.key?.toString(),
                            );
                            if (foundOption != undefined) {
                                foundOption.selected = !(foundOption.selected ?? false);
                            }
                            props.onChange(ev as any, foundOption);
                        }
                    }}
                    placeholder={translatedPlaceholder}
                    style={styles != undefined ? { ...(styles as any).root } : undefined}
                    selectedOptions={selectedOptions}
                    //value={curVal}
                    value={
                        onRenderTitle != undefined
                            ? (onRenderTitle(
                                  options.find(
                                      (o) => o.selected === true || o.key === valueAsStrOrInt,
                                  ) ?? {
                                      text: curVal,
                                      data: curVal,
                                  },
                              ) as any)
                            : ((<AdvDropdownValue>{curVal}</AdvDropdownValue>) as any)
                    }
                    listbox={{
                        ref: (a) => {
                            scrollRef(a);
                            refInner(a);
                            listCompRef.current = a;
                        },
                    }}
                    onBlur={(ev) => {
                        if (props.onBlur !== undefined) props.onBlur(ev as any);
                        setWasInteractedWith(true);
                    }}
                    disabled={
                        shouldForceDisable || (designerData == undefined && disabled === true)
                    }
                >
                    {
                        // don't activate autoFocus for this one, will break everything
                        <AdvSearchInput
                            ref={searchFieldRef as any}
                            value={searchField}
                            onValueChanged={(newVal) => {
                                if (newVal != undefined) {
                                    setSearchField(newVal);
                                }
                            }}
                            placeholder="Suche..."
                            onKeyUp={(ev) => {
                                if (ev.key == "Enter") {
                                    if (typeof props.onChange != "undefined") {
                                        props.onChange(ev as any, optionsFiltered[0]);
                                    }
                                    closeDropdown();
                                } else if (ev.key == "Tab") {
                                    closeDropdown();
                                }
                            }}
                            onBlur={(ev) => {
                                if (ev.relatedTarget != null) {
                                    ev.target.focus();
                                    ev.stopPropagation();
                                } else {
                                    dropdownRef.current?.focus();
                                    dropdownRef.current?.blur();
                                }
                            }}
                            onFocus={(ev) => {
                                ev.relatedTarget = ev.target;
                                setIsOpen(true);
                                ev.stopPropagation();
                            }}
                            onClick={(ev) => {
                                searchFieldRef.current?.focus();
                                searchFieldRef.current?.select();
                                ev.stopPropagation();
                            }}
                        ></AdvSearchInput>
                    }
                    <Virtualizer
                        numItems={optionsFiltered.length}
                        virtualizerLength={virtualizerLength}
                        bufferItems={bufferItems}
                        bufferSize={bufferSize}
                        itemSize={itemHeight}
                        key={optionsFilteredKey}
                    >
                        {(index) => {
                            const o = optionsFiltered[index];
                            let text: string | React.JSX.Element = (
                                <AdvDropdownText>{o.text ?? ""}</AdvDropdownText>
                            );
                            if (onRenderOption != undefined) {
                                text = onRenderOption(o);
                            }
                            return (
                                <AdvDropdownOption
                                    key={"dd__" + index.toString()}
                                    value={o.key?.toString()}
                                    ignoreTranslation={o.ignoreTranslation}
                                    disabled={o.disabled}
                                >
                                    {text}
                                </AdvDropdownOption>
                            );
                        }}
                    </Virtualizer>
                </VariantType>
            </Field>
            {isMobile && (
                <Drawer
                    open={isOpen}
                    onOpenChange={(_, { open }) => setIsOpen(open)}
                    separator
                    position="end"
                    onFocus={(ev) => {
                        ev.relatedTarget = ev.target;
                        setIsOpen(true);
                        ev.stopPropagation();
                    }}
                >
                    <DrawerHeader>
                        <DrawerHeaderTitle
                            action={
                                <Button
                                    appearance="subtle"
                                    aria-label="Close"
                                    icon={<AdvIcon iconName={CloseIcon.iconName} />}
                                    onClick={() => setIsOpen(false)}
                                />
                            }
                        >
                            <AdvSearchInput
                                value={searchField}
                                onValueChanged={(newVal) => {
                                    if (newVal != undefined) {
                                        setSearchField(newVal);
                                    }
                                }}
                                placeholder="Suche..."
                            ></AdvSearchInput>
                        </DrawerHeaderTitle>
                    </DrawerHeader>
                    <DrawerBody>
                        <AdvStack tokens={{ childrenGap: 15 }}>
                            {optionsFiltered.map((o, index) => {
                                let text: string | React.JSX.Element = o.text ?? "";
                                if (onRenderOption != undefined) {
                                    text = onRenderOption(o);
                                }
                                if (multiSelect) {
                                    return (
                                        <AdvCheckbox
                                            key={"dd__drawer_check" + index.toString()}
                                            label={o.text ?? ""}
                                            value={o.selected}
                                            onValueChanged={(s, ev) => {
                                                if (
                                                    s != undefined &&
                                                    ev != undefined &&
                                                    props.onChange != undefined
                                                ) {
                                                    o.selected = s;
                                                    props.onChange(ev, o);
                                                }
                                            }}
                                        ></AdvCheckbox>
                                    );
                                }
                                return (
                                    <AdvButton
                                        key={"dd__drawer" + index.toString()}
                                        simplified
                                        onClick={(ev) => {
                                            if (props.onChange != undefined) {
                                                o.selected = true;
                                                props.onChange(ev as any, o);
                                                setIsOpen(false);
                                            }
                                        }}
                                    >
                                        {text}
                                    </AdvButton>
                                );
                            })}
                        </AdvStack>
                    </DrawerBody>
                </Drawer>
            )}
        </FluentProvider>
    );
};
export const AdvDropdownImpl = React.memo(AdvDropdownImplComp, deepCompareJSXProps);

function setMultiSelectDropdownValue(
    old: string | undefined,
    option: IDropdownOption<any> | undefined,
    value: string | undefined,
) {
    const values = (old?.length ?? 0) > 0 ? old?.split(",") ?? [] : [];
    const foundIndex = values.findIndex((v) => v === option?.data.toString());
    if (foundIndex != -1) {
        if (value == undefined || option?.selected !== true) values.splice(foundIndex, 1);
        return values.join(",");
    } else if (value != undefined) {
        values.push(value);
        return values.join(",");
    }
    return old;
}

const AdvDropdownSimple = ({
    options,
    optionsData,
    optionsText,
    multiSelect,
    onValueChanged,
    value,
    ...props
}: TAdvDropdownProps) => {
    const optionsSplitted = useMemo<{
        text: any[];
        data: any[];
        hidden: (boolean | undefined)[];
        disabled: (boolean | undefined)[];
        ignoreTranslation: (boolean | undefined)[];
        itemType: (SelectableOptionMenuItemType | undefined)[];
    }>(() => {
        const res: {
            text: any[];
            data: any[];
            hidden: (boolean | undefined)[];
            disabled: (boolean | undefined)[];
            ignoreTranslation: (boolean | undefined)[];
            itemType: (SelectableOptionMenuItemType | undefined)[];
        } = {
            data: [],
            text: [],
            hidden: [],
            disabled: [],
            ignoreTranslation: [],
            itemType: [],
        };
        res.ignoreTranslation = options.map((val) => val.ignoreTranslation);
        if (optionsData == undefined && optionsText == undefined) {
            options.forEach((val, index) => {
                res.data[index] = val.data != undefined ? val.data : val.text;
                res.text[index] = val.text;
                res.hidden[index] = val.hidden;
                res.disabled[index] = val.disabled;
                res.itemType[index] = val.itemType;
            });
        } else {
            if (optionsData == undefined && optionsText != undefined) {
                optionsText.forEach((val, index) => {
                    res.data[index] = val;
                    res.text[index] = val;
                });
            } else if (optionsData != undefined) {
                res.data = optionsData.map((val) => val);
                if (optionsText != undefined) res.text = optionsText.map((val) => val);
                else res.text = optionsData.map((val) => val);
                // make them equally sized
                while (res.text.length < res.data.length) res.text.push(res.data[res.text.length]);
            }
        }
        return res;
    }, [options, optionsData, optionsText]);

    const optionKeys = useMemo(() => {
        const res: (string | number)[] = [];
        if (optionsData == undefined && optionsText == undefined) {
            options.forEach((val) => res.push(val.key));
        } else {
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let i = 0; i < optionsSplitted.data.length; ++i) res.push(nanoid());
        }
        return res;
    }, [options, optionsData, optionsSplitted.data.length, optionsText]);

    const [curVal, , setCurVal] = useAdvMemoWithUpdater(() => value, [value]);

    const optionsCached = useMemo<TAdvDropdownItem<any>[]>(() => {
        const values: string[] = [];
        if (multiSelect === true && curVal != undefined && typeof curVal == "string") {
            values.push(...curVal.split(","));
        }

        let res: TAdvDropdownItem<any>[] = [];
        res = optionsSplitted.data.map((val, index) => {
            return {
                key: optionKeys[index],
                text: optionsSplitted.text[index],
                data: val,
                hidden: optionsSplitted.hidden[index],
                disabled: optionsSplitted.disabled[index],
                ignoreTranslation: optionsSplitted.ignoreTranslation[index],
                itemType: optionsSplitted.itemType[index],
            };
        });
        return res;
    }, [
        curVal,
        multiSelect,
        optionKeys,
        optionsSplitted.data,
        optionsSplitted.disabled,
        optionsSplitted.hidden,
        optionsSplitted.ignoreTranslation,
        optionsSplitted.text,
        optionsSplitted.itemType,
    ]);

    const handleChange = useAdvCallback(
        (event: any, option?: IDropdownOption<any>) => {
            if (multiSelect === true)
                setCurVal((old) => {
                    if (typeof old == "string") {
                        return setMultiSelectDropdownValue(old, option, option?.data.toString());
                    }
                    return old;
                });
            else setCurVal(option);
            if (onValueChanged != undefined) onValueChanged(option, event);
        },
        [multiSelect, onValueChanged, setCurVal],
    );

    return (
        <AdvDropdownImpl
            {...props}
            onChange={handleChange}
            value={curVal}
            options={optionsCached}
            multiSelect={multiSelect}
        ></AdvDropdownImpl>
    );
};

const AdvDropdownComplexValues = ({
    optionsText,
    optionsTextBindingParams,
    optionsData,
    optionsDataBindingParams,
    valueBindingParams,
    translatableTextLabelBindingParams,
    translatableTextDescriptionBindingParams,
    translatableTextPlaceholderBindingParams,
    options,
    value,
    disabled,
    disabledBindingParams,
    multiSelect,
    onValueChanged,
    dataArrayIndex = 0,
    ...props
}: TAdvDropdownProps) => {
    const optionsSplitted = useMemo<{ text: any[]; data: any[] }>(() => {
        const res: { text: any[]; data: any[] } = { data: [], text: [] };
        if (optionsData == undefined && optionsText == undefined) {
            res.data = options.map((val) => {
                const data = val.data != undefined ? val.data : val.text;
                return data;
            });
            res.text = options.map((val) => {
                return val.text;
            });
        } else {
            if (optionsData == undefined && optionsText != undefined) {
                res.data = optionsText.map((val) => val);
                res.text = optionsText.map((val) => val);
            } else if (optionsData != undefined) {
                res.data = optionsData.map((val) => val);
                if (optionsText != undefined) res.text = optionsText.map((val) => val);
                else res.text = optionsData.map((val) => val);
                // make them equally sized
                while (res.text.length < res.data.length)
                    res.text.push(res.data[res.text.length - 1]);
            }
        }
        return res;
    }, [options, optionsData, optionsText]);

    const [currentOptionsText] = useAdvValueBinderAsArrayNoDataType(
        optionsTextBindingParams,
        optionsSplitted.text,
        EAdvValueDataTypes.Any,
        false,
        dataArrayIndex,
    );
    const [currentOptionsData, setOptionsData] = useAdvValueBinderAsArrayNoDataType(
        optionsDataBindingParams,
        optionsSplitted.data,
        EAdvValueDataTypes.Any,
        false,
        dataArrayIndex,
        true,
    );
    const [currentlyDisabled] = useAdvValueBinder<boolean | undefined>(
        disabledBindingParams,
        disabled,
        EAdvValueDataTypes.Boolean,
        dataArrayIndex,
    );

    const optionKeys = useMemo(() => {
        const res: (string | number)[] = [];
        if (optionsData == undefined && optionsText == undefined) {
            options.forEach((val) => res.push(val.key));
        }
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = (options ?? []).length; i < currentOptionsData.length; ++i) res.push(nanoid());

        return res;
    }, [options, optionsData, currentOptionsData.length, optionsText]);

    const valueAsStr = useMemo(() => {
        if (optionsData == undefined && optionsText == undefined) {
            return typeof value === "string"
                ? value
                : value != undefined
                ? value.key.toString()
                : "";
        } else {
            if (typeof value == "object") return value.data.toString();
            return value;
        }
    }, [optionsData, optionsText, value]);
    const [currentValue, setCurrentValue, attributes] = useAdvValueBinderNoDataType<any>(
        valueBindingParams,
        valueAsStr,
        multiSelect === true
            ? EAdvValueDataTypes.ArrayAsCommaSeperatedString
            : EAdvValueDataTypes.Any,
        dataArrayIndex,
        true,
    );

    const optionsCached = useMemo<TAdvDropdownItem<any>[]>(() => {
        const values: string[] = [];
        if (multiSelect === true && currentValue != undefined && currentValue != "") {
            values.push(...currentValue.toString().split(","));
        }

        let res: TAdvDropdownItem<any>[] = [];
        const dataUniqueness = new Map<string, { data: any; index: number }>();
        currentOptionsData.forEach((val, index) =>
            dataUniqueness.set(JSON.stringify(val), { data: val, index: index }),
        );
        res = currentOptionsData
            .filter((val, index) => (dataUniqueness.get(JSON.stringify(val))?.index ?? -1) == index)
            .map((val) => {
                const index = dataUniqueness.get(JSON.stringify(val))?.index ?? -1;

                let isSelected: boolean | undefined = undefined;
                if (values.includes(val.toString())) isSelected = true;

                return {
                    key: optionKeys[index],
                    text:
                        index >= currentOptionsText.length
                            ? typeof val == "string"
                                ? val
                                : JSON.stringify(val)
                            : currentOptionsText[index],
                    data: val,
                    selected: isSelected,
                };
            });
        return res;
    }, [multiSelect, currentValue, currentOptionsData, optionKeys, currentOptionsText]);

    const valueCached = useMemo(() => {
        if (optionsData == undefined && optionsText == undefined) {
            return typeof currentValue;
        } else {
            return optionsCached.find((val) => val.data == currentValue);
        }
    }, [currentValue, optionsCached, optionsData, optionsText]);

    const prevOptionsData = usePrevious(currentOptionsData);
    useAdvEffect(() => {
        if (
            prevOptionsData === undefined ||
            optionsDataBindingParams?.bindingTypeName != EAdvValueBinderType.BinderTypeDataProvider
        )
            return;
        // Prüfen ob wir zum ersten mal Daten bekommen
        if (prevOptionsData.length == 0 && currentOptionsData.length > 0) {
            let _currentValue = currentValue;
            // Wenn nur ein Datensatz vorhanden ist, dann den ersten auswählen (falls noch nicht ausgewählt)
            if (currentOptionsData.length == 1) _currentValue = currentOptionsData[0];
            if (currentValue !== _currentValue) setCurrentValue(currentOptionsData[0]);
            else {
                // Wenn wir Daten aus einem DataProvider bekommen, kann der DropDown den DataProvider positionieren.
                // Das müssen wir nicht nur beim Ändern (handleChange) sondern auch beim initialen Laden der Daten machen.
                if (currentValue !== undefined && currentValue != "") {
                    const index = currentOptionsData.findIndex((oc) => oc === currentValue);
                    if (index >= 0)
                        // Zum Umpositionieren benötigen wir den Index des ausgewählten Items
                        setOptionsData([index]);
                }
            }
        }
    }, [
        currentValue,
        currentOptionsData,
        optionsDataBindingParams?.bindingTypeName,
        prevOptionsData,
        setOptionsData,
        optionsDataBindingParams,
        setCurrentValue,
    ]);

    const handleChange = useAdvCallback(
        (event: any, option?: IDropdownOption<any>) => {
            let canSet = true;
            if (option != undefined) {
                const newValue =
                    multiSelect === true
                        ? setMultiSelectDropdownValue(
                              currentValue?.toString(),
                              option,
                              option.data.toString(),
                          )
                        : option.data;
                canSet = setCurrentValue(newValue);

                // Bei DataProvider: Den DP entsprechend positionieren
                if (
                    canSet &&
                    optionsDataBindingParams?.bindingTypeName ==
                        EAdvValueBinderType.BinderTypeDataProvider
                ) {
                    // Zum Umpositionieren benötigen wir den Index des ausgewählten Items
                    setOptionsData([optionsCached.findIndex((oc) => oc.data === option.data)]);
                }
            }
            if (canSet && onValueChanged != undefined) onValueChanged(option, event);
        },
        [
            currentValue,
            multiSelect,
            onValueChanged,
            optionsCached,
            optionsDataBindingParams,
            setCurrentValue,
            setOptionsData,
        ],
    );

    if (attributes.isVisible)
        return (
            <AdvDropdownImpl
                {...props}
                optionsText={optionsText}
                optionsData={optionsData}
                optionsTextBindingParams={optionsTextBindingParams}
                optionsDataBindingParams={optionsDataBindingParams}
                valueBindingParams={valueBindingParams}
                translatableTextLabelBindingParams={translatableTextLabelBindingParams}
                translatableTextDescriptionBindingParams={translatableTextDescriptionBindingParams}
                translatableTextPlaceholderBindingParams={translatableTextPlaceholderBindingParams}
                options={optionsCached}
                value={valueCached}
                multiSelect={multiSelect}
                onChange={handleChange}
                disabled={(currentlyDisabled.val ?? false) || !attributes.isEditable}
                dataArrayIndex={dataArrayIndex}
            ></AdvDropdownImpl>
        );
    else return <></>;
};

const AdvDropdownComplexLabelOrHideOrMultiSelect = ({
    optionsTextBindingParams,
    optionsDataBindingParams,
    valueBindingParams,
    translatableTextLabel,
    translatableTextLabelBindingParams,
    translatableTextDescription,
    translatableTextDescriptionBindingParams,
    translatableTextPlaceholder,
    translatableTextPlaceholderBindingParams,
    advhide,
    advhideBindingParams,
    multiSelect,
    multiSelectBindingParams,
    required,
    requiredBindingParams,
    dataArrayIndex = 0,
    onChangedEventActionParams,
    ...props
}: TAdvDropdownProps) => {
    const [labelBindingVal] = useAdvValueBinderNoDataType(
        translatableTextLabelBindingParams,
        translatableTextLabel,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );
    const [descriptionBindingVal] = useAdvValueBinderNoDataType(
        translatableTextDescriptionBindingParams,
        translatableTextDescription,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );
    const [placeholderBindingVal] = useAdvValueBinderNoDataType(
        translatableTextPlaceholderBindingParams,
        translatableTextPlaceholder,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );
    const [shouldHide] = useAdvValueBinderNoDataType(
        advhideBindingParams,
        advhide,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );
    const [isMultiSelect] = useAdvValueBinderNoDataType(
        multiSelectBindingParams,
        multiSelect ?? false,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );
    const [isRequired] = useAdvValueBinderNoDataType(
        requiredBindingParams,
        required,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );

    if (
        !IsValueBindingTrivial(optionsTextBindingParams) ||
        !IsValueBindingTrivial(optionsDataBindingParams) ||
        !IsValueBindingTrivial(valueBindingParams) ||
        !IsValueBindingTrivial(multiSelectBindingParams) ||
        !IsValueBindingTrivial(requiredBindingParams)
    ) {
        return (
            <AdvDropdownComplexValues
                {...props}
                optionsTextBindingParams={optionsTextBindingParams}
                optionsDataBindingParams={optionsDataBindingParams}
                valueBindingParams={valueBindingParams}
                translatableTextLabelBindingParams={translatableTextLabelBindingParams}
                translatableTextDescriptionBindingParams={translatableTextDescriptionBindingParams}
                translatableTextPlaceholderBindingParams={translatableTextPlaceholderBindingParams}
                advhide={shouldHide}
                advhideBindingParams={advhideBindingParams}
                multiSelect={isMultiSelect}
                multiSelectBindingParams={multiSelectBindingParams}
                required={isRequired}
                requiredBindingParams={requiredBindingParams}
                dataArrayIndex={dataArrayIndex}
                translatableTextLabel={labelBindingVal}
                translatableTextDescription={descriptionBindingVal}
                translatableTextPlaceholder={placeholderBindingVal}
                onChangedEventActionParams={onChangedEventActionParams}
            ></AdvDropdownComplexValues>
        );
    } else {
        return (
            <AdvDropdownImpl
                {...props}
                optionsTextBindingParams={optionsTextBindingParams}
                optionsDataBindingParams={optionsDataBindingParams}
                valueBindingParams={valueBindingParams}
                translatableTextLabelBindingParams={translatableTextLabelBindingParams}
                translatableTextDescriptionBindingParams={translatableTextDescriptionBindingParams}
                translatableTextPlaceholderBindingParams={translatableTextPlaceholderBindingParams}
                advhide={shouldHide}
                advhideBindingParams={advhideBindingParams}
                multiSelect={isMultiSelect}
                multiSelectBindingParams={multiSelectBindingParams}
                required={isRequired}
                requiredBindingParams={requiredBindingParams}
                dataArrayIndex={dataArrayIndex}
                translatableTextLabel={labelBindingVal}
                translatableTextDescription={descriptionBindingVal}
                translatableTextPlaceholder={placeholderBindingVal}
                onChangedEventActionParams={onChangedEventActionParams}
            ></AdvDropdownImpl>
        );
    }
};

const AdvDropdownComplex = ({
    translatableTextLabelBindingParams,
    translatableTextDescriptionBindingParams,
    translatableTextPlaceholderBindingParams,
    advhideBindingParams,
    multiSelectBindingParams,
    onChangedEventActionParams,
    ...props
}: TAdvDropdownProps) => {
    if (
        !IsValueBindingTrivial(translatableTextLabelBindingParams) ||
        !IsValueBindingTrivial(translatableTextDescriptionBindingParams) ||
        !IsValueBindingTrivial(translatableTextPlaceholderBindingParams) ||
        !IsValueBindingTrivial(advhideBindingParams) ||
        !IsValueBindingTrivial(multiSelectBindingParams) ||
        !IsWebActionTrivial(onChangedEventActionParams)
    ) {
        return (
            <AdvDropdownComplexLabelOrHideOrMultiSelect
                {...props}
                translatableTextLabelBindingParams={translatableTextLabelBindingParams}
                translatableTextDescriptionBindingParams={translatableTextDescriptionBindingParams}
                translatableTextPlaceholderBindingParams={translatableTextPlaceholderBindingParams}
                advhideBindingParams={advhideBindingParams}
                multiSelectBindingParams={multiSelectBindingParams}
                onChangedEventActionParams={onChangedEventActionParams}
            ></AdvDropdownComplexLabelOrHideOrMultiSelect>
        );
    } else {
        return (
            <AdvDropdownComplexValues
                {...props}
                translatableTextLabelBindingParams={translatableTextLabelBindingParams}
                translatableTextDescriptionBindingParams={translatableTextDescriptionBindingParams}
                translatableTextPlaceholderBindingParams={translatableTextPlaceholderBindingParams}
                advhideBindingParams={advhideBindingParams}
                multiSelectBindingParams={multiSelectBindingParams}
                onChangedEventActionParams={onChangedEventActionParams}
            ></AdvDropdownComplexValues>
        );
    }
};

/**
 * @summary Wrapper für ``Dropdown``
 * @link https://developer.microsoft.com/en-us/fluentui#/controls/web/dropdown
 */
const AdvDropdownComp = ({
    optionsTextBindingParams,
    optionsDataBindingParams,
    valueBindingParams,
    translatableTextLabelBindingParams,
    translatableTextDescriptionBindingParams,
    translatableTextPlaceholderBindingParams,
    advhideBindingParams,
    multiSelectBindingParams,
    ...props
}: TAdvDropdownProps) => {
    if (
        IsValueBindingTrivial(optionsTextBindingParams) &&
        IsValueBindingTrivial(optionsDataBindingParams) &&
        IsValueBindingTrivial(valueBindingParams) &&
        IsValueBindingTrivial(translatableTextLabelBindingParams) &&
        IsValueBindingTrivial(translatableTextDescriptionBindingParams) &&
        IsValueBindingTrivial(translatableTextPlaceholderBindingParams) &&
        IsValueBindingTrivial(advhideBindingParams) &&
        IsValueBindingTrivial(multiSelectBindingParams)
    )
        return (
            <AdvDropdownSimple
                {...props}
                optionsTextBindingParams={optionsTextBindingParams}
                optionsDataBindingParams={optionsDataBindingParams}
                valueBindingParams={valueBindingParams}
                translatableTextLabelBindingParams={translatableTextLabelBindingParams}
                translatableTextDescriptionBindingParams={translatableTextDescriptionBindingParams}
                translatableTextPlaceholderBindingParams={translatableTextPlaceholderBindingParams}
                advhideBindingParams={advhideBindingParams}
                multiSelectBindingParams={multiSelectBindingParams}
            ></AdvDropdownSimple>
        );
    else
        return (
            <AdvDropdownComplex
                {...props}
                optionsTextBindingParams={optionsTextBindingParams}
                optionsDataBindingParams={optionsDataBindingParams}
                valueBindingParams={valueBindingParams}
                translatableTextLabelBindingParams={translatableTextLabelBindingParams}
                translatableTextDescriptionBindingParams={translatableTextDescriptionBindingParams}
                translatableTextPlaceholderBindingParams={translatableTextPlaceholderBindingParams}
                advhideBindingParams={advhideBindingParams}
                multiSelectBindingParams={multiSelectBindingParams}
            ></AdvDropdownComplex>
        );
};

const AdvDropdown = React.memo(AdvDropdownComp, deepCompareJSXProps);
export default AdvDropdown;

import { useAdvRouter } from "@hooks/page/useAdvRouter";
import { useResizeDetector } from "react-resize-detector";
import "./designable";
