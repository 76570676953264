import AdvText from "@components/data/text/text";
import {
    DialogType,
    IDialogContentProps,
    IDialogProps,
    IDialogStyleProps,
    IDialogStyles,
    IStyleFunctionOrObject,
} from "@fluentui/react";
import {
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    FluentProvider,
} from "@fluentui/react-components";
import { createV9Theme } from "@fluentui/react-migration-v8-v9";
import { useAdvEffect } from "@hooks/react-overload/useAdvEffect";
import useAdvComponent from "@hooks/useAdvComponent";
import useAdvTheme from "@hooks/useAdvTheme";
import { deepCompareJSXProps } from "@utils/deep-compare";
import React, { ReactNode, useMemo, useState } from "react";

export type TAdvDialogStyles = IDialogStyles; /* do not change */
export type TAdvDialogStyleProps = IDialogStyleProps; /* do not change */
export type TAdvDialogContentStyles = IDialogStyles; /* do not change */
export type TAdvDialogType = DialogType; /* do not change */

export type TAdvDialogContentProps = Omit<IDialogContentProps, "title" | "subText" | "type"> & {
    title: string | React.JSX.Element;
    subText?: string;
    type: TAdvDialogType;
};

export type TAdvDialogProps = Omit<
    IDialogProps,
    "styles" | "dialogContentProps" | "onDismiss" | "hidden" | "isOpen"
> & {
    styles?: IStyleFunctionOrObject<TAdvDialogStyleProps, TAdvDialogStyles>;

    dialogContentProps: TAdvDialogContentProps;
    onDismiss?: (ev?: React.MouseEvent<HTMLButtonElement>) => any;
    hidden: boolean;

    footer: ReactNode | ReactNode[] | undefined;
    children?: ReactNode | ReactNode[] | undefined;
    ignoreTranslation?: boolean;
};

/**
 * @summary Wrapper für ``Dialog``
 * @link https://developer.microsoft.com/en-us/fluentui#/controls/web/dialog
 */
export const AdvDialogComp = ({
    onDismiss,
    footer,
    children,
    hidden,
    ignoreTranslation = false,
    ...props
}: TAdvDialogProps) => {
    useAdvComponent(AdvDialogComp, props);

    const [isInternallyHidden, setInternalHidden] = useState<boolean>(hidden);

    useAdvEffect(() => {
        setInternalHidden(hidden);
    }, [hidden]);

    const internalOnDismiss = () => {
        setInternalHidden(true);
        if (onDismiss) onDismiss();
    };

    const theme = useAdvTheme();
    const v9Theme = useMemo(() => createV9Theme(theme), [theme]);

    return (
        <FluentProvider theme={v9Theme} className="foreground">
            <Dialog
                {...props}
                open={!isInternallyHidden}
                onOpenChange={(_, data) => {
                    if (!data.open) internalOnDismiss();
                }}
                modalType="modal"
            >
                <DialogSurface>
                    <DialogBody>
                        <DialogTitle>{props.dialogContentProps.title}</DialogTitle>
                        <DialogContent>
                            <AdvText allowMultiline ignoreTranslation={ignoreTranslation}>
                                {props.dialogContentProps.subText}
                            </AdvText>
                            {children}
                        </DialogContent>
                        <DialogActions>{footer}</DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </FluentProvider>
    );
};

const AdvDialog = React.memo(AdvDialogComp, deepCompareJSXProps);
export default AdvDialog;
