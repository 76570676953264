import { EFieldSettingsFieldTypes } from "@components/dynamic/data-provider/types";
import AdvGridItemDesignable from "@components/layout/grid/grid-item/designable";
import AdvStackItemDesignable from "@components/layout/stack/stack-item/designable";
import {
    AdvCommonComponentAttributes,
    AdvThemeProviderProperties,
    TAdvCommonProperties,
} from "@components/other/common-properties";
import {
    EDataproviderClientOptions,
    useDataprovider,
} from "@data/dataprovider/data-provider-client";
import { LAN } from "@data/language/strings";
import { TAdvChartDataproviderDefinition } from "@feature/Designer/property-input/chart-provider-selection";
import { DefaultComponentCategory } from "@feature/Designer/types/category";
import { TAdvDesignerComponentProps } from "@feature/Designer/types/component-props";
import { EComponentTypeData } from "@feature/Designer/types/component-type";
import {
    AdvProperty,
    getSelectedComponentStyle,
    registerDesignableComponent,
} from "@feature/Designer/utils";
import { IPalette } from "@fluentui/react";
import {
    TAdvValueBindingParams,
    useAdvValueBinderAsArrayNoDataType,
    useAdvValueBinderMultiNoDataType,
    useAdvValueBinderNoDataType,
} from "@hooks/dynamic/useAdvValueBinder";
import { toAdvText } from "@hooks/language/useTranslation";
import { useAdvCallback } from "@hooks/react-overload/useAdvCallback";
import { useAdvEffect } from "@hooks/react-overload/useAdvEffect";
import { useAdvObjMemo } from "@hooks/useAdvObjMemo";
import useAdvTheme from "@hooks/useAdvTheme";
import { ChartIcon } from "@themes/icons";
import { colorToHex, getContrast, getHashColorFromString } from "@utils/colors";
import { EAdvValueDataTypes } from "@utils/data-types";
import { ServerStrToLocalDateStr, ServerStrToLocalDateTimeStr } from "@utils/date";
import { deepCompareJSXProps } from "@utils/deep-compare";
import deepCopy from "@utils/deep-copy";
import { mergeObjects } from "@utils/styling";
import {
    ArcElement,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    ChartOptions,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { nanoid } from "nanoid";
import React, { Dispatch, SetStateAction, useMemo, useRef, useState } from "react";
import { Bar, Doughnut, Line, Pie } from "react-chartjs-2";
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";
import { useResizeDetector } from "react-resize-detector";
import AdvText from "../text";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    PointElement,
    ArcElement,
    ChartDataLabels,
    Title,
    Tooltip,
    Legend,
);

function textColorBasedOnBGColor(bgColor: string) {
    const whiteContrast = getContrast(bgColor, "#ffffff");
    const blackContrast = getContrast(bgColor, "#000000");

    return whiteContrast > blackContrast ? "#ffffff" : "#000000";
}

export type TAdvBarChatDataset = {
    label: string;
    labelBindingParams?: TAdvValueBindingParams;
    data: number[];
    dataBindingParams?: TAdvValueBindingParams;
    backgroundColor: string[];
    colorMode?: "default" | "single" | "randomize" | "defined";
};

const AdvChartDataproviderSelectedBinder = ({
    providerKey,
    //providerSettings,
    setRelevantRecords,
}: {
    providerKey: string;
    //providerSettings: TAdvChartDataproviderDefinition;
    setRelevantRecords: Dispatch<SetStateAction<any[]>>;
}) => {
    const {
        isLoaded: dpIsLoaded,
        isEOF: dpIsEOF,
        getData: dpGetData,
        getCurrentRecords: dpGetCurrentRecords,
        loadData: dpLoadData,
        hasErrors: dpHasErrors,
        //getSorting: dpGetSorting,
    } = useDataprovider(providerKey, {
        [EDataproviderClientOptions.AutoLoadCount]: 50,
        [EDataproviderClientOptions.GetProviderFieldsWithoutData]: true,
    });
    useAdvEffect(() => {
        const isDpLoaded = dpIsLoaded();

        // Wenn noch oder zumindest noch nicht alle
        // (noch nicht EoF), dann die Daten jetzt
        // komplett laden
        if (!isDpLoaded || !dpIsEOF()) {
            dpLoadData(0, -1);
            return;
        }

        const provData = dpGetData();
        const curRecs = dpGetCurrentRecords();
        if (provData && !dpHasErrors()) {
            setRelevantRecords(() => {
                return curRecs; //die curRecs sind direkt im gewuenschten Format
            });
        }
    }, [
        dpIsLoaded,
        dpIsEOF,
        dpLoadData,
        dpGetData,
        dpGetCurrentRecords,
        dpHasErrors,
        setRelevantRecords,
    ]);
    return <></>;
};

const AdvChartDataproviderBinderComp = ({
    providerKey,
    providerSettings,
    setDatasets,
    setLabels,
    useRelevantRecords,
    providedRelevantRecords = [],
    flipAxis,
    hideUnusedDatasetLabels = false,
    hideUnusedAxisLabels = false,
}: {
    providerKey: string;
    providerSettings: TAdvChartDataproviderDefinition;
    setDatasets: Dispatch<SetStateAction<TAdvBarChatDataset[]>>;
    setLabels: Dispatch<SetStateAction<string[]>>;
    useRelevantRecords: boolean;
    providedRelevantRecords?: any[]; //nur verwendet wenn useRelevantRecords==true
    flipAxis: boolean;
    hideUnusedDatasetLabels?: boolean;
    hideUnusedAxisLabels?: boolean;
}) => {
    const {
        isLoaded: dpIsLoaded,
        isEOF: dpIsEOF,
        getData: dpGetData,
        loadData: dpLoadData,
        hasErrors: dpHasErrors,
        getSorting: dpGetSorting,
    } = useDataprovider(providerKey, {
        [EDataproviderClientOptions.AutoLoadCount]: 50,
        [EDataproviderClientOptions.GetProviderFieldsWithoutData]: true,
    });

    type TChartProviderDatasetsResult = {
        datasetLabels: string[];
        axisLabels: string[];
        valuesMapping: Array<{
            setLabelIndex: number;
            axisLabelIndex: number;
            value: number;
            color: string;
        }>;
    };

    const theme = useAdvTheme();

    const providerDatasets = useAdvObjMemo(() => {
        const isDpLoaded = dpIsLoaded();

        const res: TChartProviderDatasetsResult = {
            datasetLabels: [],
            axisLabels: [],
            valuesMapping: [],
        };

        const axislabels: Array<{
            valueFieldname: string;
            axisLabel: string;
            caption: string;
        }> = [];
        const datasetlabels: Array<{
            valueFieldname: string;
            datasetLabel: string;
            caption: string;
        }> = [];

        // Wenn noch oder zumindest noch nicht alle
        // (noch nicht EoF), dann die Daten jetzt
        // komplett laden
        if (!isDpLoaded || !dpIsEOF()) {
            dpLoadData(0, -1);
            return res;
        }

        const provData = dpGetData();
        if (provData && !dpHasErrors()) {
            if (
                providerSettings.fieldsForValue === undefined ||
                providerSettings.fieldsForValue.length <= 0
            ) {
                return res;
            }

            const keys = Object.keys(provData);
            if (keys.length <= 0) {
                return res;
            }

            const hasMultipleValuefields = providerSettings.fieldsForValue.length > 1;
            //sind mehrere Datenreihen bzw Spalten angegeben, aus denen Werte benutzt werden?

            //falls die Zuordnungen getauscht werden sollen (zB fuer Diagramm-Typ Kuchen), einfach die Felder tauschen
            const adjustedFieldnameForAxislabel = flipAxis
                ? providerSettings.fieldnameForSubsetlabel
                : providerSettings.fieldnameForAxislabel;
            const adjustedFieldnameForSubsetlabel = flipAxis
                ? providerSettings.fieldnameForAxislabel
                : providerSettings.fieldnameForSubsetlabel;
            //ob verschiedene Wertefelder in eine einzelne Reihe sollen, wechselt auch, falls die Zuordnungen wechseln:
            const isAdjustedMultivaluesInOneDataset =
                flipAxis !== providerSettings.isMultivaluesInOneDataset;

            /*
            Im folgenden werden Achsen- und Datenreihenbeschriftungen erstellt, bevor wir durch die Werte gehen.
            Das ist notwendig, weil wir sie auch sortieren muessen.
            Wir verwenden alle Provider-Daten, um alle moeglichen Beschriftungen darzustellen (auch aktuell nicht-selektierte)
            */
            if (!isAdjustedMultivaluesInOneDataset) {
                //die verschiedenen Wertefelder werden auf verschieden Datenreihen aufgeteilt
                //alle Achsenbeschriftungen erstellen:
                if (
                    adjustedFieldnameForAxislabel !== undefined &&
                    adjustedFieldnameForAxislabel !== ""
                ) {
                    provData[adjustedFieldnameForAxislabel].Values.forEach((val) => {
                        //suchen, ob wir diese Achsenbeschriftung schon haben:
                        let axisLabelIndex = axislabels.findIndex((oneLabel) => {
                            return oneLabel.axisLabel == val && oneLabel.valueFieldname == "";
                        });
                        //wenn nicht dann hinzufuegen
                        if (!(axisLabelIndex > -1)) {
                            axisLabelIndex =
                                axislabels.push({
                                    axisLabel: val as string,
                                    valueFieldname: "",
                                    caption: val as string,
                                }) - 1;
                        }
                    });
                }
                //alle Datenreihenbeschriftungen erstellen (komplexer, da Aufteilung der evtl mehreren Wertefelder):
                if (
                    adjustedFieldnameForSubsetlabel !== undefined &&
                    adjustedFieldnameForSubsetlabel !== ""
                ) {
                    provData[adjustedFieldnameForSubsetlabel].Values.forEach((val) => {
                        providerSettings.fieldsForValue.forEach((oneFieldForValue) => {
                            //suchen, ob wir diese Kombi schon als Datenreihenbeschriftung haben:
                            let datasetLabelIndex = datasetlabels.findIndex((oneLabel) => {
                                return (
                                    oneLabel.valueFieldname == oneFieldForValue.fieldname &&
                                    oneLabel.datasetLabel == val
                                );
                            });
                            //wenn nicht dann hinzufuegen
                            if (!(datasetLabelIndex > -1)) {
                                datasetLabelIndex =
                                    datasetlabels.push({
                                        valueFieldname: oneFieldForValue.fieldname,
                                        datasetLabel: val as string,
                                        caption:
                                            val === "" || val === undefined
                                                ? oneFieldForValue.caption //keine subsets, dann den Feldnamen als Beschriftung nehmen
                                                : (val as string) + //wenn es subsets gibt, dann muss beides in die Beschriftung
                                                  (hasMultipleValuefields
                                                      ? " (" + oneFieldForValue.caption + ")"
                                                      : ""),
                                    }) - 1;
                            }
                        });
                    });
                } else {
                    //es gibt keine Aufteilung auf verschiedene Datenreihen ausser durch die (evtl mehreren) Wertefelder
                    providerSettings.fieldsForValue.forEach((oneFieldForValue) => {
                        //suchen, ob wir dieses Wertefeld schon als Datenreihenbeschriftung haben:
                        let datasetLabelIndex = datasetlabels.findIndex((oneLabel) => {
                            return (
                                oneLabel.valueFieldname == oneFieldForValue.fieldname &&
                                oneLabel.datasetLabel == ""
                            );
                        });
                        //wenn nicht dann hinzufuegen
                        if (!(datasetLabelIndex > -1)) {
                            datasetLabelIndex =
                                datasetlabels.push({
                                    valueFieldname: oneFieldForValue.fieldname,
                                    datasetLabel: "",
                                    caption: oneFieldForValue.caption, //keine subsets, also Beschriftung des Feldnamen als Beschriftung nehmen
                                }) - 1;
                        }
                    });
                }
            } else {
                //die verschiedenen Wertefelder werden auf verschieden Achsenbeschriftungen aufgeteilt
                //alle Datenreihenbeschriftungen erstellen:
                if (
                    adjustedFieldnameForSubsetlabel !== undefined &&
                    adjustedFieldnameForSubsetlabel !== ""
                ) {
                    provData[adjustedFieldnameForSubsetlabel].Values.forEach((val) => {
                        //suchen, ob wir diese Datenreihenbeschriftungen schon haben:
                        let datasetLabelIndex = datasetlabels.findIndex((oneLabel) => {
                            return oneLabel.datasetLabel == val && oneLabel.valueFieldname == "";
                        });
                        //wenn nicht dann hinzufuegen
                        if (!(datasetLabelIndex > -1)) {
                            datasetLabelIndex =
                                datasetlabels.push({
                                    datasetLabel: val as string,
                                    valueFieldname: "",
                                    caption: val as string,
                                }) - 1;
                        }
                    });
                }
                //alle Achsenbeschriftungen erstellen (komplexer, da Aufteilung der evtl mehreren Wertefelder):
                if (
                    adjustedFieldnameForAxislabel !== undefined &&
                    adjustedFieldnameForAxislabel !== ""
                ) {
                    provData[adjustedFieldnameForAxislabel].Values.forEach((val) => {
                        providerSettings.fieldsForValue.forEach((oneFieldForValue) => {
                            //suchen, ob wir diese Kombi schon als Achsenbeschriftung haben:
                            let axisLabelIndex = axislabels.findIndex((oneLabel) => {
                                return (
                                    oneLabel.valueFieldname == oneFieldForValue.fieldname &&
                                    oneLabel.axisLabel == val
                                );
                            });
                            //wenn nicht dann hinzufuegen
                            if (!(axisLabelIndex > -1)) {
                                axisLabelIndex =
                                    axislabels.push({
                                        valueFieldname: oneFieldForValue.fieldname,
                                        axisLabel: val as string,
                                        caption:
                                            (val as string) + //es muss beides in die Beschriftung
                                            (hasMultipleValuefields
                                                ? " (" + oneFieldForValue.caption + ")"
                                                : ""),
                                    }) - 1;
                            }
                        });
                    });
                } else {
                    //es gibt keine Aufteilung auf verschiedene Datenreihen ausser durch die (evtl mehreren) Wertefelder
                    providerSettings.fieldsForValue.forEach((oneFieldForValue) => {
                        //suchen, ob wir dieses Wertefeld schon als Datenreihenbeschriftung haben:
                        let axisLabelIndex = axislabels.findIndex((oneLabel) => {
                            return (
                                oneLabel.valueFieldname == oneFieldForValue.fieldname &&
                                oneLabel.axisLabel == ""
                            );
                        });
                        //wenn nicht dann hinzufuegen
                        if (!(axisLabelIndex > -1)) {
                            axisLabelIndex =
                                axislabels.push({
                                    valueFieldname: oneFieldForValue.fieldname,
                                    axisLabel: "",
                                    caption: oneFieldForValue.caption, //keine Achsenbeschriftungsunterteilung, also den Feldnamen als Beschriftung nehmen
                                }) - 1;
                        }
                    });
                }
            }

            /*
            Im folgenden werden die Datenreihen und die Achsenbeschriftungen gemaess Dataprovider-Vorgabe sortiert
            */

            const sortingColumns = dpGetSorting();
            //Achsenbeschriftung sortieren:
            const sortingEntryAxis = sortingColumns.find((sortingColumn) => {
                return sortingColumn.name == adjustedFieldnameForAxislabel;
            });
            if (sortingEntryAxis !== undefined) {
                axislabels.sort((a, b) => a.axisLabel.localeCompare(b.axisLabel));
                if (sortingEntryAxis.sortDesc) {
                    axislabels.reverse();
                }
            }
            //Datenreihen sortieren:
            const sortingEntryDataset = sortingColumns.find((sortingColumn) => {
                return sortingColumn.name == adjustedFieldnameForSubsetlabel;
            });
            if (sortingEntryDataset !== undefined) {
                datasetlabels.sort((a, b) => a.datasetLabel.localeCompare(b.datasetLabel));
                if (sortingEntryDataset.sortDesc) {
                    datasetlabels.reverse();
                }
            }

            /*
            Im folgenden erstellen wir die relevantRecords (anzuzeigende Daten).
            ntweder es werden welche reingereicht oder wir nutzen alle Provider-Daten
            */
            const relevantRecords = useRelevantRecords ? providedRelevantRecords : []; //sollen die reingereichten verwendet werden
            if (!useRelevantRecords) {
                //wenn nicht, dann relevanteRecords selbst aus dem Datenprovider bauen
                const provDataItemCount = Math.max(
                    ...keys.map((k) => {
                        return provData[k].Values.length;
                    }),
                );
                const itemCount = Math.min(provDataItemCount, Number.MAX_SAFE_INTEGER);
                for (let i = 0; i < itemCount; i++) {
                    let newRow: any = {};
                    if (
                        adjustedFieldnameForAxislabel !== undefined &&
                        adjustedFieldnameForAxislabel !== ""
                    ) {
                        newRow = {
                            ...newRow,
                            [adjustedFieldnameForAxislabel]: provData[adjustedFieldnameForAxislabel]
                                .Values[i] as string,
                        };
                    }
                    if (
                        adjustedFieldnameForSubsetlabel !== undefined &&
                        adjustedFieldnameForSubsetlabel !== ""
                    ) {
                        newRow = {
                            ...newRow,
                            [adjustedFieldnameForSubsetlabel]: provData[
                                adjustedFieldnameForSubsetlabel
                            ].Values[i] as string,
                        };
                    }
                    providerSettings.fieldsForValue.forEach((oneFieldForValue) => {
                        newRow = {
                            ...newRow,
                            [oneFieldForValue.fieldname]: provData[oneFieldForValue.fieldname]
                                .Values[i] as number,
                        };
                    });

                    relevantRecords.push(newRow);
                }
            }

            /*
            Im folgenden gehen wir alle relevantRecords durch, um die Werte zu den Achsen-/Datenreihenbeschriftungen zuzuordnen.
            */
            if (!isAdjustedMultivaluesInOneDataset) {
                //die verschiedenen Wertespalten werden als verschiedene Datenreihen (bzw innere Einteilung) benutzt

                relevantRecords.forEach((aRow) => {
                    const aRowAxisLabel = //der Wert der in der Spalte steht, aus der wir die Achsenbeschriftungen nehmen
                        adjustedFieldnameForAxislabel !== undefined &&
                        adjustedFieldnameForAxislabel !== ""
                            ? (aRow[adjustedFieldnameForAxislabel] as string)
                            : "";
                    //suchen, ob wir diese Achsenbeschriftung schon haben:
                    let axisLabelIndex = axislabels.findIndex((oneLabel) => {
                        return oneLabel.axisLabel == aRowAxisLabel && oneLabel.valueFieldname == "";
                    });
                    //wenn nicht dann hinzufuegen (sollte eigentlich gar nicht vorkommen)
                    if (!(axisLabelIndex > -1)) {
                        axisLabelIndex =
                            axislabels.push({
                                axisLabel: aRowAxisLabel,
                                valueFieldname: "",
                                caption: aRowAxisLabel,
                            }) - 1;
                    }
                    //falls eine weitere Unterteiltung der Daten gewuenscht (also fieldnameForSubsetlabel gesetzt ist), das entsprechende Subset-Label zwischenspeichern:
                    const aRowDatasetLabel =
                        adjustedFieldnameForSubsetlabel != ""
                            ? (aRow[adjustedFieldnameForSubsetlabel] as string)
                            : "";
                    providerSettings.fieldsForValue.forEach((oneFieldForValue) => {
                        //suchen, ob wir diese Kombi als Datenreihenbeschriftung schon haben:
                        let datasetLabelIndex = datasetlabels.findIndex((oneLabel) => {
                            return (
                                oneLabel.valueFieldname == oneFieldForValue.fieldname &&
                                oneLabel.datasetLabel == aRowDatasetLabel
                            );
                        });
                        //wenn nicht dann hinzufuegen
                        if (!(datasetLabelIndex > -1)) {
                            datasetLabelIndex =
                                datasetlabels.push({
                                    valueFieldname: oneFieldForValue.fieldname,
                                    datasetLabel: aRowDatasetLabel,
                                    caption:
                                        aRowDatasetLabel == ""
                                            ? oneFieldForValue.caption //keine subsets, dann den Feldnamen als Beschriftung nehmen
                                            : aRowDatasetLabel + //wenn es subsets gibt, dann muss beides in die Beschriftung
                                              (hasMultipleValuefields
                                                  ? " (" + oneFieldForValue.caption + ")"
                                                  : ""),
                                }) - 1;
                        }
                        const value = aRow[oneFieldForValue.fieldname];
                        if (!isNaN(+value)) {
                            res.valuesMapping.push({
                                axisLabelIndex: axisLabelIndex,
                                setLabelIndex: datasetLabelIndex,
                                value: +value,
                                color:
                                    oneFieldForValue.chosenDefinedColor ?? //falls verfuegbar manuell hinzugefuegte Farbe
                                    theme.palette[
                                        oneFieldForValue.chosenThemeColor as keyof IPalette
                                    ] ?? //sonst falls verfuegbar Theme-Farbe
                                    getHashColorFromString(
                                        flipAxis
                                            ? axislabels[axisLabelIndex].caption
                                            : datasetlabels[datasetLabelIndex].caption,
                                    ), //sonst automatische Farbe
                            });
                        }
                    });
                });
            } else {
                //die verschiedenen Wertespalten werden als verschiedene Achsenbereiche (bzw auessere Einteilung) benutzt

                relevantRecords.forEach((aRow) => {
                    const aRowSetLabel =
                        adjustedFieldnameForSubsetlabel !== undefined &&
                        adjustedFieldnameForSubsetlabel !== ""
                            ? (aRow[adjustedFieldnameForSubsetlabel] as string)
                            : ""; //der Wert der in der Spalte steht, aus der wir die Datenreihenbeschriftung nehmen
                    //suchen, ob wir diese Datenreihenbeschriftung schon haben:
                    let datasetLabelIndex = datasetlabels.findIndex((oneLabel) => {
                        return (
                            oneLabel.datasetLabel == aRowSetLabel && oneLabel.valueFieldname == ""
                        );
                    });
                    //wenn nicht dann hinzufuegen
                    if (!(datasetLabelIndex > -1)) {
                        datasetLabelIndex =
                            datasetlabels.push({
                                datasetLabel: aRowSetLabel ?? "",
                                valueFieldname: "",
                                caption: aRowSetLabel,
                            }) - 1;
                    }
                    //falls eine weitere Unterteiltung der Daten gewuenscht (also fieldnameForAxisLabel gesetzt ist), das entsprechende Axis-Label zwischenspeichern:
                    const aRowAxisLabel =
                        adjustedFieldnameForAxislabel != ""
                            ? (aRow[adjustedFieldnameForAxislabel] as string)
                            : "";
                    providerSettings.fieldsForValue.forEach((oneFieldForValue) => {
                        //suchen, ob wir diese Kombi schon als Achsenbeschriftung haben:
                        let axisLabelIndex = axislabels.findIndex((oneLabel) => {
                            return (
                                oneLabel.valueFieldname == oneFieldForValue.fieldname &&
                                oneLabel.axisLabel == aRowAxisLabel
                            );
                        });
                        //wenn nicht dann hinzufuegen
                        if (!(axisLabelIndex > -1)) {
                            axisLabelIndex =
                                axislabels.push({
                                    valueFieldname: oneFieldForValue.fieldname,
                                    axisLabel: aRowAxisLabel,
                                    caption:
                                        aRowAxisLabel === "" || aRowAxisLabel === undefined
                                            ? oneFieldForValue.caption //keine subsets, dann den Feldnamen als Beschriftung nehmen
                                            : aRowAxisLabel + //wenn es subsets gibt, dann muss beides in die Beschriftung
                                              (hasMultipleValuefields
                                                  ? " (" + oneFieldForValue.caption + ")"
                                                  : ""),
                                }) - 1;
                        }
                        const value = aRow[oneFieldForValue.fieldname];
                        if (!isNaN(+value)) {
                            res.valuesMapping.push({
                                axisLabelIndex: axisLabelIndex,
                                setLabelIndex: datasetLabelIndex,
                                value: value,
                                color:
                                    oneFieldForValue.chosenDefinedColor ?? //falls verfuegbar manuell hinzugefuegte Farbe
                                    theme.palette[
                                        oneFieldForValue.chosenThemeColor as keyof IPalette
                                    ] ?? //sonst falls verfuegbar Theme-Farbe
                                    getHashColorFromString(
                                        flipAxis
                                            ? axislabels[axisLabelIndex].caption
                                            : datasetlabels[datasetLabelIndex].caption,
                                    ), //sonst automatische Farbe
                            });
                        }
                    });
                });
            }

            //nur relevante Captions weitergeben
            res.datasetLabels.push(...datasetlabels.map((val) => val.caption));
            res.axisLabels.push(...axislabels.map((val) => val.caption));
        }
        return res;
    }, [
        dpIsLoaded,
        dpIsEOF,
        dpGetData,
        dpHasErrors,
        dpLoadData,
        providerSettings.fieldsForValue,
        providerSettings.fieldnameForSubsetlabel,
        providerSettings.fieldnameForAxislabel,
        providerSettings.isMultivaluesInOneDataset,
        flipAxis,
        dpGetSorting,
        useRelevantRecords,
        providedRelevantRecords,
        theme.palette,
    ]);

    const emptyProviderDataset: TChartProviderDatasetsResult = {
        axisLabels: [],
        datasetLabels: [],
        valuesMapping: [],
    };
    const [previousProviderDatasets, setPreviousProviderDatasets] = useState(emptyProviderDataset);

    useAdvEffect(() => {
        if (deepCompareJSXProps(previousProviderDatasets, providerDatasets)) return;
        setPreviousProviderDatasets(providerDatasets);
        const res: TAdvBarChatDataset[] = [];
        const axisLabels = providerDatasets.axisLabels;
        const didFindDataForAxis: boolean[] = [];
        providerDatasets.datasetLabels.forEach((valSetLabel, valsetlabelindex) => {
            const backgroundColors: string[] = [];
            let didFindDataForDataset = false; //trackt, ob ueberhaupt Daten in dieser Datenreihe gefunden wurden
            const thisData = axisLabels.map((_valAxisLabel, valaxislabelindex) => {
                const foundMappings = providerDatasets.valuesMapping.filter(
                    (valMapping) =>
                        valMapping.setLabelIndex === valsetlabelindex &&
                        valMapping.axisLabelIndex === valaxislabelindex,
                );
                let sumValue = 0; //vorerst immer Summe, natuerlich waaren auch andere Aggregationen moeglich
                let foundColor = "";
                foundMappings.forEach((oneFoundMapping) => {
                    didFindDataForDataset = true;
                    didFindDataForAxis[oneFoundMapping.axisLabelIndex] = true;
                    sumValue = +sumValue + +oneFoundMapping.value;
                    if (foundColor === "") {
                        //wenn noch keine Farbe gesetzt wurde, die Farbe des gefundenen Mappings nehmen
                        foundColor = oneFoundMapping.color;
                    }
                });
                backgroundColors.push(foundColor);
                return sumValue;
            });

            if (didFindDataForDataset || !hideUnusedDatasetLabels) {
                //fuer die Uebersichtlichkeit nur Datenreihen eintragen, die auch Daten haben
                res.push({
                    label: valSetLabel,
                    data: thisData,
                    backgroundColor: backgroundColors,
                    colorMode: "defined",
                });
            }
        });
        const resLabels = hideUnusedAxisLabels
            ? providerDatasets.axisLabels.filter((oneAxisLabel, index) => {
                  //Achsenbeschriftung filtern und dabei zugehoerige (leere) Daten entfernen
                  if (didFindDataForAxis[index] === true) {
                      return true;
                  } else {
                      res.forEach((oneDataset) => {
                          delete oneDataset.data[index];
                      });
                      return false;
                  }
              })
            : providerDatasets.axisLabels;
        setLabels((old) => {
            if (JSON.stringify(old) != JSON.stringify(resLabels)) return resLabels;
            return old;
        });
        setDatasets((old) => {
            //zuvor entfernte Werte ganz entfernen:
            res.forEach((oneDataset) => {
                oneDataset.data = oneDataset.data.filter((val) => {
                    return !isNaN(val);
                });
            });
            if (JSON.stringify(old) != JSON.stringify(res)) return res;
            return old;
        });
    }, [
        setDatasets,
        providerDatasets,
        setLabels,
        previousProviderDatasets,
        hideUnusedAxisLabels,
        hideUnusedDatasetLabels,
    ]);

    return <></>;
};

const AdvChartDataproviderBinder = React.memo(AdvChartDataproviderBinderComp);

const AdvChartDataSetBinder = ({
    dataset,
    datasetIndex,
    dataArrayIndex,
    setDatasets,
    showSelectedRowsOnly,
}: {
    dataset: TAdvBarChatDataset;
    datasetIndex: number;
    dataArrayIndex: number;
    setDatasets: Dispatch<SetStateAction<TAdvBarChatDataset[]>>;
    showSelectedRowsOnly: boolean;
}) => {
    const [datasetLabel] = useAdvValueBinderNoDataType(
        dataset.labelBindingParams,
        dataset.label,
        EAdvValueDataTypes.String,
        dataArrayIndex,
    );

    //Datensatz doppelt fuer entweder alle oder nur selektierte
    const [datasetDataRealAll, , attributesAll] = useAdvValueBinderAsArrayNoDataType(
        dataset.dataBindingParams,
        dataset.data,
        EAdvValueDataTypes.Any,
        false,
        dataArrayIndex,
    );
    const [datasetDataRealSelected, , attributesSelected] = useAdvValueBinderMultiNoDataType(
        dataset.dataBindingParams,
        dataset.data,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );

    const datasetDataReal =
        showSelectedRowsOnly ?? false ? datasetDataRealSelected : datasetDataRealAll;
    const attributes = showSelectedRowsOnly ?? false ? attributesSelected : attributesAll;

    const datasetData = useMemo(() => {
        switch (attributes.fieldType) {
            case EFieldSettingsFieldTypes.datetime:
                return datasetDataReal.map((d) => ServerStrToLocalDateTimeStr(d));
            case EFieldSettingsFieldTypes.date:
                return datasetDataReal.map((d) => ServerStrToLocalDateStr(d));
            default:
                return datasetDataReal;
        }
    }, [attributes.fieldType, datasetDataReal]);

    useAdvEffect(() => {
        setDatasets((old) => {
            const res = deepCopy(old);
            if (datasetIndex < old.length) {
                res[datasetIndex].label = datasetLabel;
                res[datasetIndex].backgroundColor = dataset.backgroundColor;
                res[datasetIndex].data = datasetData;
            } else {
                res.push({
                    label: datasetLabel,
                    backgroundColor: dataset.backgroundColor,
                    data: datasetData,
                });
            }
            if (JSON.stringify(old) != JSON.stringify(res)) return res;
            return old;
        });
    }, [dataset.backgroundColor, datasetData, datasetIndex, datasetLabel, setDatasets]);

    return <></>;
};

export type TAdvChartImpl = {
    labels: string[];
    datasets: TAdvBarChatDataset[];
    style: any;
    options: any;
};

const AdvChartTypeBarImpl = ({
    labels,
    datasets,
    style,
    options,
    shouldShowValuesAsExtraYAchsis,
}: TAdvChartImpl & { shouldShowValuesAsExtraYAchsis: boolean }) => {
    const theme = useAdvTheme();
    const datasetOverload = useMemo(() => {
        /*const datasetsCopy = datasets.map((val) => {
            if (shouldShowPatterns) {
                return {
                    ...val,
                    backgroundColor: pattern.generate(val.backgroundColor),
                };
            } else {
                return val;
            }
        }); //so koennte eine Patternverwendung aussehen
*/
        if (!shouldShowValuesAsExtraYAchsis) {
            return datasets;
        } else {
            const extraSet = {
                data: deepCopy(datasets[0].data),
                label: "Right dataset",
                backgroundColor: "rgba(0, 0, 0, 0)",
                borderColor: "rgba(0, 0, 0, 0)",
                border: 0,
                fill: false,
                yAxisID: "right-y-axis",
            };

            return [...datasets, extraSet];
        }
    }, [datasets, shouldShowValuesAsExtraYAchsis]);

    const optionsOverload = useMemo(() => {
        const res = mergeObjects(options, {
            plugins: {
                datalabels: false,
            },
        });
        if (!shouldShowValuesAsExtraYAchsis) return res;
        else {
            return mergeObjects(res, {
                scales: {
                    "right-y-axis": {
                        gridLines: {
                            offsetGridLines: true,
                        },
                        position: "right",
                        ticks: {
                            callback: function (value: any, index: any) {
                                return datasetOverload[datasetOverload.length - 1].data[index]; // hide original y1 labels
                            },
                            autoSkip: false,
                            color: theme.palette.neutralSecondary,
                        },
                        grid: {
                            color: "transparent",
                        },
                    },
                    y: {
                        gridLines: {
                            offsetGridLines: true,
                        },
                        ticks: {
                            autoSkip: false,
                            color: theme.palette.neutralSecondary,
                        },
                        grid: {
                            color: colorToHex(theme.palette.black) + "26",
                        },
                    },
                    x: {
                        display: false,
                    },
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: (tooltipItem: any) => {
                                if (tooltipItem.datasetIndex == datasetOverload.length - 1)
                                    return "";
                            },
                            beforeLabel: (tooltipItem: any) => {
                                if (tooltipItem.datasetIndex == datasetOverload.length - 1)
                                    return "";
                            },
                            afterLabel: (tooltipItem: any) => {
                                if (tooltipItem.datasetIndex == datasetOverload.length - 1)
                                    return "";
                            },
                        },
                    },
                },
            });
        }
    }, [
        datasetOverload,
        options,
        shouldShowValuesAsExtraYAchsis,
        theme.palette.black,
        theme.palette.neutralSecondary,
    ]);

    return (
        <Bar
            data={{ labels: labels, datasets: datasetOverload }}
            style={style}
            options={optionsOverload}
        ></Bar>
    );
};

const AdvChartTypeLineImpl = ({ labels, datasets, style, options }: TAdvChartImpl) => {
    const dataLabelColors = useMemo<string[]>(() => {
        if (datasets.length >= 1) {
            const bgColor = datasets[0].backgroundColor;
            if (Array.isArray(bgColor)) {
                return bgColor.map((c) => {
                    return textColorBasedOnBGColor(c);
                });
            } else {
                return [textColorBasedOnBGColor(bgColor)];
            }
        } else return ["#ffffff"];
    }, [datasets]);

    const optionsOverload = useMemo(() => {
        return mergeObjects(options, {
            plugins: {
                datalabels: {
                    display: false, //ausblenden der Labels IM Diagramm
                    color: dataLabelColors,
                },
            },
        });
    }, [dataLabelColors, options]);
    return (
        <Line
            data={{ labels: labels, datasets: datasets }}
            style={style}
            options={optionsOverload}
        ></Line>
    );
};

const AdvChartTypePieImpl = ({ labels, datasets, style, options }: TAdvChartImpl) => {
    const dataLabelColors = useMemo<string[]>(() => {
        if (datasets.length >= 1) {
            const bgColor = datasets[0].backgroundColor;
            if (Array.isArray(bgColor)) {
                return bgColor.map((c) => {
                    return textColorBasedOnBGColor(c);
                });
            } else {
                return [textColorBasedOnBGColor(bgColor)];
            }
        } else return ["#ffffff"];
    }, [datasets]);

    const optionsOverload = useMemo(() => {
        return mergeObjects(options, {
            scales: {
                x: {
                    grid: {
                        display: false,
                    },
                    title: {
                        display: false,
                    },
                    ticks: {
                        display: false,
                    },
                },
                y: {
                    grid: {
                        display: false,
                    },
                    title: {
                        display: false,
                    },
                    ticks: {
                        display: false,
                    },
                },
            },
            plugins: {
                ...ChartDataLabels,
                datalabels: {
                    color: dataLabelColors,
                    display: false, //ausblenden der Labels IM Diagramm
                    formatter: function (value: any, context: any) {
                        return context.chart.data.labels[context.dataIndex];
                    },
                },
            },
        });
    }, [dataLabelColors, options]);
    return (
        <Pie
            data={{ labels: labels, datasets: datasets }}
            style={style}
            options={optionsOverload}
        ></Pie>
    );
};

const AdvChartTypeDoughnutImpl = ({ labels, datasets, style, options }: TAdvChartImpl) => {
    const dataLabelColors = useMemo<string[]>(() => {
        if (datasets.length >= 1) {
            const bgColor = datasets[0].backgroundColor;
            if (Array.isArray(bgColor)) {
                return bgColor.map((c) => {
                    return textColorBasedOnBGColor(c);
                });
            } else {
                return [textColorBasedOnBGColor(bgColor)];
            }
        } else return ["#ffffff"];
    }, [datasets]);

    const optionsOverload = useMemo(() => {
        return mergeObjects(options, {
            scales: {
                x: {
                    grid: {
                        display: false,
                    },
                    title: {
                        display: false,
                    },
                    ticks: {
                        display: false,
                    },
                },
                y: {
                    grid: {
                        display: false,
                    },
                    title: {
                        display: false,
                    },
                    ticks: {
                        display: false,
                    },
                },
            },
            plugins: {
                ...ChartDataLabels,
                datalabels: {
                    color: dataLabelColors,
                    display: false, //ausblenden der Labels IM Diagramm
                    formatter: function (value: any, context: any) {
                        return context.chart.data.labels[context.dataIndex];
                    },
                },
            },
        });
    }, [dataLabelColors, options]);

    return (
        <Doughnut
            data={{ labels: labels, datasets: datasets }}
            style={style}
            options={optionsOverload}
        ></Doughnut>
    );
};

type TAdvGaugeProps = TAdvChartImpl & {
    currentValue?: number;
    currentValueBindingParams?: TAdvValueBindingParams;
    maxValue?: number;
    maxValueBindingParams?: TAdvValueBindingParams;
    dataArrayIndex?: number;
};

const AdvChartTypeGaugeImpl = ({
    labels,
    datasets,
    style,
    options,
    currentValue = 50,
    currentValueBindingParams,
    maxValue = 100,
    maxValueBindingParams,
    dataArrayIndex = 0,
}: TAdvGaugeProps) => {
    const ref = useRef<ChartJSOrUndefined<"doughnut", number[], string>>();

    const [curVal] = useAdvValueBinderNoDataType(
        currentValueBindingParams,
        currentValue,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );
    const [maxVal] = useAdvValueBinderNoDataType(
        maxValueBindingParams,
        maxValue,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );

    const drawNeedle = useAdvCallback((radiusPercentage: number, radianAngle: number) => {
        if (ref.current == undefined) return;
        const canvas = ref.current.canvas;
        const ctx = canvas.getContext("2d");
        if (ctx == null) return;
        const cw = canvas.offsetWidth;
        const ch = canvas.offsetHeight;
        const cx = cw / 2;
        const cy = ch - ch / 4;

        ctx.translate(cx, cy);
        ctx.rotate(radianAngle);
        // needle background
        ctx.beginPath();
        ctx.moveTo(0, -7);
        ctx.lineTo((radiusPercentage / 100) * (cw / 2), 0);
        ctx.lineTo(0, 7);
        ctx.fillStyle = "rgba(255, 255, 255, 1.0)";
        ctx.fill();
        // needle foreground
        ctx.beginPath();
        ctx.moveTo(0, -5);
        ctx.lineTo((radiusPercentage / 100) * (cw / 2), 0);
        ctx.lineTo(0, 5);
        ctx.fillStyle = "rgba(120, 180, 120, 1.0)";
        ctx.fill();
        // needle circle
        ctx.rotate(-radianAngle);
        ctx.translate(-cx, -cy);
        // back
        ctx.beginPath();
        ctx.fillStyle = "rgba(255, 255, 255, 1.0)";
        ctx.arc(cx, cy, 9, 0, Math.PI * 2);
        ctx.fill();
        // fore
        ctx.beginPath();
        ctx.fillStyle = "rgba(120, 180, 120, 1.0)";
        ctx.arc(cx, cy, 7, 0, Math.PI * 2);
        ctx.fill();
    }, []);

    const labelsAndDatasetsOverload = useMemo(() => {
        if (datasets.length < 1) return { labels: labels, datasets: datasets };

        const datasetDataAndIndex = datasets[0].data.map((d, dIndex) => {
            return { d: d, i: dIndex };
        });
        const sortedDataset = datasetDataAndIndex.sort((a, b) => a.d - b.d);

        const labelsAndIndex = labels.map((l, lIndex) => {
            return { l: l, i: lIndex };
        });
        const labelsSorted = labelsAndIndex.sort((l1, l2) => {
            const val1 = datasetDataAndIndex.find((d) => d.i == l1.i)?.d ?? 0;
            const val2 = datasetDataAndIndex.find((d) => d.i == l2.i)?.d ?? 0;
            return val1 - val2;
        });
        const finalLabels = labelsSorted.map((l) => l.l);

        let curVal = 0;
        const finalData = sortedDataset.map((d) => {
            const res = d.d - curVal;
            curVal = d.d;
            return res;
        });
        const lastData = sortedDataset.length > 0 ? sortedDataset[sortedDataset.length - 1].d : 0;
        if (lastData < maxVal) {
            finalData.push(maxVal - lastData);
            finalLabels.push("");
        }
        const finalDataset = {
            ...datasets[0],
            data: finalData,
            backgroundColor: finalData.map((_, fIndex) => {
                const colors = ["red", "orange", "gold", "yellow", "green"];
                const indexPerc = Math.floor(
                    (fIndex / (finalData.length - 1)) * (colors.length - 1),
                );
                return colors[indexPerc];
            }),
        };
        return {
            labels: finalLabels,
            datasets: [finalDataset],
        };
    }, [datasets, labels, maxVal]);

    const dataLabelColors = useMemo<string[]>(() => {
        if (labelsAndDatasetsOverload.datasets.length >= 1) {
            const bgColor = labelsAndDatasetsOverload.datasets[0].backgroundColor;
            if (Array.isArray(bgColor)) {
                return bgColor.map((c) => {
                    return textColorBasedOnBGColor(c);
                });
            } else {
                return [textColorBasedOnBGColor(bgColor)];
            }
        } else return ["#ffffff"];
    }, [labelsAndDatasetsOverload.datasets]);

    const optionsOverload = useMemo(() => {
        return mergeObjects(options, {
            rotation: -90,
            circumference: 180,
            scales: {
                x: {
                    grid: {
                        display: false,
                    },
                    title: {
                        display: false,
                    },
                    ticks: {
                        display: false,
                    },
                },
                y: {
                    grid: {
                        display: false,
                    },
                    title: {
                        display: false,
                    },
                    ticks: {
                        display: false,
                    },
                },
            },
            plugins: {
                ...ChartDataLabels,
                datalabels: {
                    color: dataLabelColors,
                    formatter: function (value: any, context: any) {
                        return context.chart.data.labels[context.dataIndex];
                    },
                },
                legend: {
                    display: false,
                },
                tooltips: {
                    enabled: false,
                },
            },

            layout: {
                padding: {
                    left: -10,
                },
            },
            animation: {
                onComplete: () => {
                    drawNeedle(
                        85,
                        (-180 * Math.PI) / 180 + ((curVal / maxVal) * 180 * Math.PI) / 180,
                    );
                },
                onProgress: () => {
                    drawNeedle(
                        85,
                        (-180 * Math.PI) / 180 + ((curVal / maxVal) * 180 * Math.PI) / 180,
                    );
                },
            },
        });
    }, [curVal, dataLabelColors, drawNeedle, maxVal, options]);

    return (
        <Doughnut
            data={{
                labels: labelsAndDatasetsOverload.labels,
                datasets: labelsAndDatasetsOverload.datasets,
            }}
            style={style}
            options={optionsOverload}
            ref={ref}
        ></Doughnut>
    );
};

export enum EAdvChartType {
    Bar = "bar",
    Line = "line",
    Pie = "pie",
    Doughnut = "doughnut",
    Gauge = "gauge",
}

export type TAdvChartProps = TAdvDesignerComponentProps &
    TAdvCommonProperties & {
        label: string;
        labelBindingParams?: TAdvValueBindingParams;
        providerKey: string;
        providerSettings: TAdvChartDataproviderDefinition;
        labels: string[];
        labelsBindingParams?: TAdvValueBindingParams;
        datasets: TAdvBarChatDataset[];
        stacked?: boolean;
        horizontal?: boolean;
        rangeFromZero?: boolean;
        chartType?: EAdvChartType;
        showOnlySelected?: boolean;

        currentValue?: number;
        currentValueBindingParams?: TAdvValueBindingParams;
        maxValue?: number;
        maxValueBindingParams?: TAdvValueBindingParams;

        showLegend?: boolean;
        showLegendBindingParams?: TAdvValueBindingParams;
        showXAchsis?: boolean;
        showXAchsisBindingParams?: TAdvValueBindingParams;
        showYAchsis?: boolean;
        showYAchsisBindingParams?: TAdvValueBindingParams;

        showValuesAsExtraYAchsis?: boolean;
        showValuesAsExtraYAchsisBindingParams?: TAdvValueBindingParams;
    };

const HelperComp = ({ children }: { children: React.JSX.Element }) => {
    const { ref: refInner, height: innerHeight } = useResizeDetector<HTMLDivElement>({
        handleHeight: true,
    });
    const [chart, setChart] = useState(<></>);
    useAdvEffect(() => {
        setChart(
            <div
                key={nanoid()}
                style={{
                    maxHeight: innerHeight ?? undefined,
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                }}
            >
                {children}
            </div>,
        );
    }, [children, innerHeight]);
    return (
        <div style={{ height: "100%", position: "relative" }} ref={refInner}>
            {chart}
        </div>
    );
};

const AdvChartComp = ({
    label,
    labelBindingParams,
    providerKey,
    providerSettings,
    labels,
    labelsBindingParams,
    datasets,
    currentValue,
    currentValueBindingParams,
    maxValue,
    maxValueBindingParams,
    advhide = false,
    advhideBindingParams,
    dataArrayIndex = 0,
    designerData,
    stacked = false,
    horizontal = false,
    rangeFromZero = false,
    showLegend = true,
    showLegendBindingParams,
    showXAchsis = true,
    showXAchsisBindingParams,
    showYAchsis = true,
    showYAchsisBindingParams,
    showValuesAsExtraYAchsis = false,
    showValuesAsExtraYAchsisBindingParams,
    chartType = EAdvChartType.Bar,
    showOnlySelected = false,
}: TAdvChartProps) => {
    const [labelValue] = useAdvValueBinderNoDataType(
        labelBindingParams,
        label,
        EAdvValueDataTypes.String,
        dataArrayIndex,
    );

    const [labelsValueRealAll, , attributesAll] = useAdvValueBinderAsArrayNoDataType(
        labelsBindingParams,
        labels,
        EAdvValueDataTypes.Any,
        false,
        dataArrayIndex,
    );

    const [labelsValueRealSelected, , attributesSelected] = useAdvValueBinderMultiNoDataType(
        labelsBindingParams,
        labels,
        EAdvValueDataTypes.Any,
        dataArrayIndex,
    );

    const labelsValueReal =
        showOnlySelected ?? false ? labelsValueRealSelected : labelsValueRealAll;
    const attributes = showOnlySelected ?? false ? attributesSelected : attributesAll;

    const labelsValueOld = useMemo(() => {
        switch (attributes.fieldType) {
            case EFieldSettingsFieldTypes.datetime:
                return labelsValueReal.map((d) => ServerStrToLocalDateTimeStr(d));
            case EFieldSettingsFieldTypes.date:
                return labelsValueReal.map((d) => ServerStrToLocalDateStr(d));
            default:
                return labelsValueReal;
        }
    }, [attributes.fieldType, labelsValueReal]);

    const [labelsValue, setLabels] = useState(labelsValueOld ?? []);

    const [shouldHide] = useAdvValueBinderNoDataType(
        advhideBindingParams,
        advhide,
        EAdvValueDataTypes.Boolean,
        dataArrayIndex,
    );

    const [shouldShowLegend] = useAdvValueBinderNoDataType(
        showLegendBindingParams,
        showLegend,
        EAdvValueDataTypes.Boolean,
        dataArrayIndex,
    );

    const [shouldShowXAchsis] = useAdvValueBinderNoDataType(
        showXAchsisBindingParams,
        showXAchsis,
        EAdvValueDataTypes.Boolean,
        dataArrayIndex,
    );

    const [shouldShowYAchsis] = useAdvValueBinderNoDataType(
        showYAchsisBindingParams,
        showYAchsis,
        EAdvValueDataTypes.Boolean,
        dataArrayIndex,
    );

    const [shouldShowValuesAsExtraYAchsis] = useAdvValueBinderNoDataType(
        showValuesAsExtraYAchsisBindingParams,
        showValuesAsExtraYAchsis,
        EAdvValueDataTypes.Boolean,
        dataArrayIndex,
    );

    const theme = useAdvTheme();
    const options = useMemo<ChartOptions<any>>(() => {
        return {
            //chartJS-Einstellungen fuer alle Typen
            maintainAspectRatio: false,
            color: theme.palette.neutralPrimary,
            scales: {
                x: {
                    grid: {
                        color: theme.palette.neutralSecondary,
                        display: shouldShowXAchsis,
                    },
                    ticks: {
                        color: theme.palette.neutralSecondary,
                    },
                    stacked: stacked,
                },
                y: {
                    grace: "5%",
                    beginAtZero: rangeFromZero,
                    grid: {
                        color: theme.palette.neutralSecondary,
                        display: shouldShowYAchsis,
                    },
                    ticks: {
                        color: theme.palette.neutralSecondary,
                    },
                    stacked: stacked,
                },
            },
            animation: showOnlySelected
                ? {}
                : {
                      duration: 0, //workaround, solange Auswahl-Aenderung auch rerender ausloest (siehe #159464)
                  },
            plugins: {
                legend: shouldShowLegend
                    ? {
                          position: "top" as const,
                          labels: { color: theme.palette.neutralSecondary },
                      }
                    : false,
                title: {
                    display: labelValue != "",
                    text: labelValue,
                    color: theme.palette.neutralPrimary,
                },
            },
            indexAxis: horizontal ? "y" : "x",
        };
    }, [
        horizontal,
        labelValue,
        rangeFromZero,
        shouldShowLegend,
        shouldShowXAchsis,
        shouldShowYAchsis,
        showOnlySelected,
        stacked,
        theme.palette.neutralPrimary,
        theme.palette.neutralSecondary,
    ]);

    const [datasetsValue, setDatasets] = useState(datasets ?? []);
    const relevantRecordsDefault: any[] = [];
    const [relevantRecordsValue, setRelevantRecords] = useState(relevantRecordsDefault);

    const uniqueKey = useMemo(() => nanoid(), []);

    const data = useMemo(() => {
        const resultData = {
            labels: labelsValue,
            datasets: datasetsValue.map((d) => {
                //chartJS-Einstelungen auf Datensatzebene
                const bgColor =
                    d.colorMode === "randomize"
                        ? d.data.map((_, index) => {
                              if (
                                  index < 10 &&
                                  index <
                                      (Array.isArray(d.backgroundColor) ? d.backgroundColor : [])
                                          .length
                              ) {
                                  return Array.isArray(d.backgroundColor)
                                      ? index < d.backgroundColor.length
                                          ? theme.palette[
                                                d.backgroundColor[index] as keyof IPalette
                                            ]
                                          : theme.palette[d.backgroundColor[0] as keyof IPalette]
                                      : theme.palette[d.backgroundColor as keyof IPalette];
                              }
                              let r = Math.floor(Math.random() * 256);
                              let g = Math.floor(Math.random() * 256);
                              let b = Math.floor(Math.random() * 256);
                              // if all three are above the half, remove it from one
                              if (r > 127 && g > 127 && b > 127) {
                                  switch (Math.floor(Math.random() * 3)) {
                                      case 0:
                                          r -= 128;
                                      case 1:
                                          g -= 128;
                                      case 2:
                                          b -= 128;
                                  }
                              }
                              // if all three are below the half, add it to one
                              if (r <= 127 && g <= 127 && b <= 127) {
                                  switch (Math.floor(Math.random() * 3)) {
                                      case 0:
                                          r += 128;
                                      case 1:
                                          g += 128;
                                      case 2:
                                          b += 128;
                                  }
                              }

                              return (
                                  "rgb(" +
                                  r.toString() +
                                  ", " +
                                  g.toString() +
                                  ", " +
                                  b.toString() +
                                  ")"
                              );
                          })
                        : d.colorMode === "default"
                        ? d.data.map((_, index) => {
                              const altIndex = index % 2;
                              if (altIndex == 0) {
                                  return Array.isArray(d.backgroundColor)
                                      ? altIndex < d.backgroundColor.length
                                          ? theme.palette[
                                                d.backgroundColor[altIndex] as keyof IPalette
                                            ]
                                          : theme.palette[d.backgroundColor[0] as keyof IPalette]
                                      : theme.palette[d.backgroundColor as keyof IPalette];
                              } else {
                                  return Array.isArray(d.backgroundColor)
                                      ? altIndex < d.backgroundColor.length
                                          ? theme.palette[
                                                d.backgroundColor[altIndex] as keyof IPalette
                                            ]
                                          : theme.palette[d.backgroundColor[0] as keyof IPalette]
                                      : theme.palette[d.backgroundColor as keyof IPalette];
                              }
                          })
                        : d.colorMode === "single"
                        ? Array.isArray(d.backgroundColor)
                            ? [d.backgroundColor[0]]
                            : [d.backgroundColor]
                        : d.colorMode === "defined"
                        ? Array.isArray(d.backgroundColor)
                            ? d.backgroundColor
                            : [d.backgroundColor]
                        : Array.isArray(d.backgroundColor)
                        ? [theme.palette[d.backgroundColor[0] as keyof IPalette]]
                        : [theme.palette[d.backgroundColor as keyof IPalette]];

                //noch ersten Eintrag korrigieren, falls er leer ist, damit die Legende stimmt:
                const bgColorCorrected = deepCopy(bgColor);
                if (bgColorCorrected[0] === "") {
                    bgColorCorrected[0] = bgColorCorrected.find((val) => val !== "") ?? "";
                }
                return {
                    ...d,
                    backgroundColor: bgColorCorrected,
                    borderColor: bgColorCorrected, //macht die Linien farbig
                    hoverBackgroundColor: bgColorCorrected,
                    hoverBorderColor: bgColorCorrected,
                    pointRadius: 6,
                    pointHoverRadius: 8,
                    hoverOffset: chartType === EAdvChartType.Pie ? 10 : 0, //mehr Abstand fuer gehovertes Kuchenstueck
                    borderRadius: chartType === EAdvChartType.Line ? 20 : 0, //macht die Linien bei Liniendiagrammen dicker
                };
            }),
        };
        return resultData;
    }, [chartType, datasetsValue, labelsValue, theme.palette]);

    const style = useMemo<React.CSSProperties>(() => {
        let style: React.CSSProperties = {};
        if ((designerData?.isSelected ?? false) && (designerData?.renderAsDesigner ?? false))
            style = mergeObjects(style, {
                ...getSelectedComponentStyle(theme, true),
            } as React.CSSProperties);
        style = mergeObjects(style, {
            maxHeight: "100%",
            height: "100%",
        });
        return style;
    }, [designerData?.isSelected, designerData?.renderAsDesigner, theme]);

    const chartComp = useMemo(() => {
        switch (chartType) {
            case EAdvChartType.Bar:
                return (
                    <AdvChartTypeBarImpl
                        labels={data.labels}
                        datasets={data.datasets}
                        style={style}
                        options={options}
                        shouldShowValuesAsExtraYAchsis={shouldShowValuesAsExtraYAchsis}
                    ></AdvChartTypeBarImpl>
                );
            case EAdvChartType.Line:
                return (
                    <AdvChartTypeLineImpl
                        labels={data.labels}
                        datasets={data.datasets}
                        style={style}
                        options={options}
                    ></AdvChartTypeLineImpl>
                );
            case EAdvChartType.Pie:
                return (
                    <AdvChartTypePieImpl
                        labels={data.labels}
                        datasets={data.datasets}
                        style={style}
                        options={options}
                    ></AdvChartTypePieImpl>
                );
            case EAdvChartType.Doughnut:
                return (
                    <AdvChartTypeDoughnutImpl
                        labels={data.labels}
                        datasets={data.datasets}
                        style={style}
                        options={options}
                    ></AdvChartTypeDoughnutImpl>
                );
            case EAdvChartType.Gauge:
                return (
                    <AdvChartTypeGaugeImpl
                        labels={data.labels}
                        datasets={data.datasets}
                        style={style}
                        options={options}
                        dataArrayIndex={dataArrayIndex}
                        currentValue={currentValue}
                        currentValueBindingParams={currentValueBindingParams}
                        maxValue={maxValue}
                        maxValueBindingParams={maxValueBindingParams}
                    ></AdvChartTypeGaugeImpl>
                );
        }
    }, [
        chartType,
        currentValue,
        currentValueBindingParams,
        data.datasets,
        data.labels,
        dataArrayIndex,
        maxValue,
        maxValueBindingParams,
        options,
        shouldShowValuesAsExtraYAchsis,
        style,
    ]);

    if (shouldHide === true) return <></>;
    if (designerData != undefined) return <AdvText>Platzhalter Diagramm</AdvText>; //<AdvFontIcon hidden={false} iconName="ChartArea"></AdvFontIcon>;
    return (
        <HelperComp>
            <>
                {providerKey !== undefined || providerKey === "" ? (
                    showOnlySelected ? ( //wenn nur selektierte angezeigt werden sollen
                        <>
                            <AdvChartDataproviderSelectedBinder //Komponente die bei Neuauswahl relevantRecords aktualisiert
                                providerKey={providerKey}
                                setRelevantRecords={setRelevantRecords}
                            ></AdvChartDataproviderSelectedBinder>
                            <AdvChartDataproviderBinder
                                providerKey={providerKey}
                                providerSettings={providerSettings}
                                setDatasets={setDatasets}
                                setLabels={setLabels}
                                useRelevantRecords={true} //sagt der Komponente, dass sie nicht alle Eintraege selbst laden und anzeigen soll, sondern auf die relevantRecords zurueckgreifen soll
                                providedRelevantRecords={relevantRecordsValue}
                                flipAxis={
                                    chartType === EAdvChartType.Pie ||
                                    chartType === EAdvChartType.Doughnut
                                }
                                hideUnusedDatasetLabels //leere Datensaetze nicht anzeigen
                                hideUnusedAxisLabels={
                                    //leere Achsenbeschriftung (bzw Kuchenstuecke) nicht anzeigen
                                    chartType === EAdvChartType.Pie ||
                                    chartType === EAdvChartType.Doughnut
                                }
                            ></AdvChartDataproviderBinder>
                        </>
                    ) : (
                        <AdvChartDataproviderBinder
                            providerKey={providerKey}
                            providerSettings={providerSettings}
                            setDatasets={setDatasets}
                            setLabels={setLabels}
                            useRelevantRecords={false}
                            flipAxis={
                                chartType === EAdvChartType.Pie ||
                                chartType === EAdvChartType.Doughnut
                            }
                        ></AdvChartDataproviderBinder>
                    )
                ) : (
                    datasets.map((d, dIndex) => {
                        return (
                            <AdvChartDataSetBinder
                                dataset={d}
                                datasetIndex={dIndex}
                                setDatasets={setDatasets}
                                dataArrayIndex={dataArrayIndex}
                                showSelectedRowsOnly={showOnlySelected}
                                key={uniqueKey + "dataset_host" + dIndex.toString()}
                            ></AdvChartDataSetBinder>
                        );
                    })
                )}
                {chartComp}
            </>
        </HelperComp>
    );
};

const AdvChart = React.memo(AdvChartComp, deepCompareJSXProps);
export default AdvChart;

registerDesignableComponent({
    staticData: {
        name: LAN.CHART.text,
        type: EComponentTypeData.Chart,
        supportsChildren: false,
        category: DefaultComponentCategory.Display,
        icon: ChartIcon,
    },
    properties: [
        AdvProperty.Text.createSuggestion(
            toAdvText(LAN.TITLE),
            "label",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.CHART_LABEL_DESCR),
            "Chart-Label",
        ),
        AdvProperty.List.create(
            toAdvText(LAN.CHART_LABELS),
            toAdvText(LAN.LABELS),
            "labels",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.CHART_LABELS_DESCR),
            [],
        ),
        AdvProperty.List.createChartDataset(
            toAdvText(LAN.CHART_DATASETS),
            toAdvText(LAN.DATASETS),
            "datasets",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.CHART_DATASETS_DESCR),
            [],
        ),
        AdvProperty.Text.createRecoilSelectProvider(
            toAdvText(LAN.PROVIDER),
            "providerKey", //das muss auch "providerKey" sein, weil in table-fields.tsx darauf eingegangen wird (per Endless-Table-Konstante)
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.CHART_PROVIDER_KEY_DESCR),
        ),
        AdvProperty.Object.createChartDataprovider(
            toAdvText(LAN.CHART_PROVIDER_DEFINTION),
            "providerSettings",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.CHART_PROVIDER_DEFINTION_DESCR),
            {
                fieldnameForAxislabel: "",
                fieldnameForSubsetlabel: "",
                fieldsForValue: [],
                isMultivaluesInOneDataset: false,
            },
        ),
        AdvProperty.Number.create(
            toAdvText(LAN.CHART_CURRENT_VALUE),
            "currentValue",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.CHART_CURRENT_VALUE_DESCR),
            50,
        ),
        AdvProperty.Number.create(
            toAdvText(LAN.CHART_MAX_VALUE),
            "maxValue",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.CHART_MAX_VALUE_DESCR),
            100,
        ),
        AdvProperty.Boolean.createToggle(
            toAdvText(LAN.DIRECTION),
            "horizontal",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.CHART_HORIZONTAL_DESCR),
            false,
            LAN.HORIZONTAL.text,
            LAN.VERTICAL.text,
        ),
        AdvProperty.Boolean.create(
            toAdvText(LAN.STACKED),
            "stacked",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.CHART_STACKED_DESCR),
            false,
        ),
        AdvProperty.Boolean.create(
            toAdvText(LAN.CHART_RANGEFROMZERO),
            "rangeFromZero",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.CHART_RANGEFROMZERO_DESCR),
            false,
        ),
        AdvProperty.Boolean.create(
            toAdvText(LAN.CHART_SHOWSELECTEDROWSONLY),
            "showOnlySelected",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.CHART_SHOWSELECTEDROWSONLY_DESCR),
            false,
        ),
        AdvProperty.Boolean.create(
            toAdvText(LAN.SHOW_LEGEND),
            "showLegend",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.CHART_SHOW_LEGEND_DESCR),
            true,
        ),
        AdvProperty.Boolean.create(
            toAdvText(LAN.SHOW_X_ACHSIS),
            "showXAchsis",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.CHART_SHOW_X_ACHSIS_DESCR),
            true,
        ),
        AdvProperty.Boolean.create(
            toAdvText(LAN.SHOW_Y_ACHSIS),
            "showYAchsis",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.CHART_SHOW_Y_ACHSIS_DESCR),
            true,
        ),
        AdvProperty.Boolean.create(
            toAdvText(LAN.SHOW_VALUES_AS_EXTRA_Y_ACHSIS),
            "showValuesAsExtraYAchsis",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.CHART_SHOW_VALUES_AS_EXTRA_Y_ACHSIS_DESCR),
            false,
        ),
        AdvProperty.Text.createSelect(
            toAdvText(LAN.CHART_TYPE),
            "chartType",
            toAdvText(LAN.GENERAL),
            toAdvText(LAN.CHART_TYPE_DESCR),
            0,
            true,
            EAdvChartType.Bar,
            EAdvChartType.Line,
            EAdvChartType.Pie,
            EAdvChartType.Doughnut,
            EAdvChartType.Gauge,
        ),
        ...AdvCommonComponentAttributes,
        ...AdvThemeProviderProperties,
        ...AdvStackItemDesignable.CommonProperties,
        ...AdvGridItemDesignable.CommonProperties,
    ],
    propertiesBuilders: [],
    presets: [],
});
